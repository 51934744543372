import { CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { PaymentStatusChip } from 'pages/admin/ManageClients/PaymentStatusChip';
import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { bff } from 'shared/api';
import { Section } from 'shared/components';
import { PaymentTrackerReply } from 'shared/types/protoc-gen/bffbillsystem';
import { QUERY_KEYS } from 'shared/types/react-query';
import { commify } from 'shared/utils/strings';

export const PaymentTrackerSection = () => {
  const { clientId } = useParams();
  const { data: paymentTracker, isPending: getPaymentTrackerPending } = useQuery({
    queryKey: [QUERY_KEYS.ADMIN_CLIENT_PAYMENT_TRACKER, { clientId }],
    queryFn: async () => {
      const res = await bff.get<PaymentTrackerReply>(`bff/bill/payments/tracker`, {
        params: {
          agreement_id: Number(clientId),
        },
      });

      return res.data;
    },
  });

  return (
    <Grid container data-testid="payment-tracker-section">
      <Grid item xs={6}>
        <Section
          renderSubHeaderStartContent={
            getPaymentTrackerPending ? <CircularProgress size={24} /> : undefined
          }
          title="Service fee"
        >
          <Stack
            px={5}
            py={3}
            spacing={3}
            sx={{
              borderRight: theme => `1px solid ${theme.colors.functional.subject.border}`,
              borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
            }}
          >
            <KeyValue
              label="Status"
              value={
                paymentTracker?.serviceFee?.status ? (
                  <PaymentStatusChip status={paymentTracker?.serviceFee?.status} />
                ) : (
                  ''
                )
              }
            />
            <KeyValue
              label="Payment period"
              value={
                paymentTracker?.serviceFee?.paymentPeriodFrom &&
                paymentTracker?.serviceFee?.paymentPeriodTo
                  ? [
                      dayjs(paymentTracker?.serviceFee?.paymentPeriodFrom).format('YYYY/MM/DD'),
                      dayjs(paymentTracker?.serviceFee?.paymentPeriodTo).format('YYYY/MM/DD'),
                    ].join(' - ')
                  : ''
              }
            />
            <KeyValue
              label="Balance"
              value={
                paymentTracker?.serviceFee?.balance
                  ? [commify(paymentTracker?.serviceFee?.balance), 'USDT'].join(' ')
                  : ''
              }
            />
          </Stack>
        </Section>
      </Grid>
      <Grid item xs={6}>
        <Section
          renderSubHeaderStartContent={
            getPaymentTrackerPending ? <CircularProgress size={24} /> : undefined
          }
          title="Withdraw fee"
        >
          <Stack
            px={5}
            py={3}
            spacing={3}
            sx={{
              borderRight: theme => `1px solid ${theme.colors.functional.subject.border}`,
              borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
            }}
          >
            <KeyValue
              label="Status"
              value={
                paymentTracker?.withdrawFee?.status ? (
                  <PaymentStatusChip status={paymentTracker?.withdrawFee?.status} />
                ) : (
                  ''
                )
              }
            />
            <KeyValue
              label="Payment period"
              value={
                paymentTracker?.withdrawFee?.paymentPeriodFrom &&
                paymentTracker?.withdrawFee?.paymentPeriodTo
                  ? [
                      dayjs(paymentTracker?.withdrawFee?.paymentPeriodFrom).format('YYYY/MM/DD'),
                      dayjs(paymentTracker?.withdrawFee?.paymentPeriodTo).format('YYYY/MM/DD'),
                    ].join(' - ')
                  : ''
              }
            />
            <KeyValue
              label="Overdraft"
              value={
                paymentTracker?.withdrawFee?.overdraft
                  ? [
                      commify(paymentTracker?.withdrawFee?.overdraft),
                      paymentTracker?.withdrawFee?.currency,
                    ].join(' ')
                  : ''
              }
            />
          </Stack>
        </Section>
      </Grid>
    </Grid>
  );
};

const KeyValue = ({ label, value }: { label: string; value: ReactNode }) => {
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Typography variant="bodySmallM">{label}</Typography>
      {typeof value === 'string' ? <Typography variant="bodySmall">{value}</Typography> : value}
    </Stack>
  );
};
