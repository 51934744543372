import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { IconButtonCopy } from 'pages/admin/ManageClients/IconButtonCopy';
import { FormProvider, useFormContext } from 'react-hook-form';
import {
  FormNumericTextField,
  FormSelectDateField,
  FormTextFieldBase,
  Label,
} from 'shared/components/form';
import { FormSelectField, FormSelectItem } from 'shared/components/form/fields/FormSelectField';
import { FormTimezonePickerField } from 'shared/components/form/fields/FormTimezonePickerField';
import { FormGroupContainer } from 'shared/components/form/form-group/FormGroupContainer';
import { CreatePaymentRequest } from 'shared/types/protoc-gen/bffbillsystem';

interface FormValues extends Omit<CreatePaymentRequest, 'agreementId'> {}

interface IClientPaymentForm {
  submitButtonLabelText: string;
  submitButtonLoading: boolean;
  writeMode?: boolean;
}

export const ClientPaymentForm = ({
  submitButtonLabelText,
  submitButtonLoading,
  writeMode = true,
}: IClientPaymentForm) => {
  const form = useFormContext<FormValues>();

  return (
    <FormProvider {...form}>
      <Stack
        data-testid="client-payment-form"
        px={{ md: 5, sm: 3, xs: 3 }}
        py={3}
        spacing={5}
        sx={
          !writeMode
            ? {
                '.MuiInputBase-root.Mui-disabled': {
                  background: '#fff',
                  borderRadius: 0,
                },
              }
            : undefined
        }
      >
        <FormGroupContainer
          data-testid="client-payment-form-general-information"
          title="General Information"
        >
          <Stack>
            <Label data-testid="type_label" label="Type">
              <FormSelectField<FormSelectItem>
                data-testid="type_field"
                disabled={!writeMode}
                fieldConfig={{ name: 'type', label: 'Type' }}
                id="type_field"
                items={[
                  {
                    label: 'Service fee',
                    value: 'Service fee',
                  },
                  { label: 'Withdraw fee', value: 'Withdraw fee' },
                  { label: 'Other fee', value: 'Other fee' },
                ]}
              />
            </Label>
            <Label data-testid="subType_label" label="Sub Type" pt={2}>
              <FormTextFieldBase<FormValues>
                InputProps={
                  !writeMode
                    ? {
                        endAdornment: <IconButtonCopy<FormValues> field="subType" />,
                      }
                    : undefined
                }
                data-testid="subType_field"
                disabled={!writeMode}
                fieldConfig={{ name: 'subType' }}
                id="subType_field"
              />
            </Label>
            <Label data-testid="status_label" label="Status">
              <FormSelectField<FormSelectItem>
                data-testid="status_field"
                disabled={!writeMode}
                fieldConfig={{ name: 'status' }}
                id="status_field"
                items={[
                  { label: 'Awaiting', value: 'Awaiting' },
                  {
                    label: 'Paid',
                    value: 'Paid',
                  },
                  {
                    label: 'Overdue',
                    value: 'Overdue',
                  },
                  {
                    label: 'Paid After Overdue',
                    value: 'PaidAfterOverdue',
                  },
                ]}
              />
            </Label>

            <Stack pt={2} spacing={2}>
              <Stack alignItems="start" direction="row" spacing={3}>
                <Label data-testid="amount_label" label="Amount">
                  <FormNumericTextField<FormValues>
                    data-testid="amount_field"
                    disabled={!writeMode}
                    fieldConfig={{ name: 'amount', label: 'Amount' }}
                    helperTextShrink
                    hideLabel
                    id="amount_field"
                  />
                </Label>
                <Label data-testid="currency_label" label="Currency">
                  <FormSelectField<FormSelectItem>
                    data-testid="currency_field"
                    disabled={!writeMode}
                    fieldConfig={{ name: 'currency', label: 'Currency' }}
                    id="currency_field"
                    items={[
                      {
                        label: 'USDT',
                        value: 'USDT',
                      },
                      {
                        label: 'ETH',
                        value: 'ETH',
                      },
                      {
                        label: 'BTC',
                        value: 'BTC',
                      },
                      {
                        label: 'USDC',
                        value: 'USDC',
                      },
                    ]}
                  />
                </Label>
              </Stack>

              <Stack alignItems="start" direction="row" spacing={3}>
                <Label data-testid="paymentPeriodFrom_label" label="Payment Period From">
                  <FormSelectDateField
                    InputProps={
                      !writeMode
                        ? {
                            endAdornment: <IconButtonCopy<FormValues> field="paymentPeriodFrom" />,
                          }
                        : undefined
                    }
                    data-testid="paymentPeriodFrom_field"
                    disabled={!writeMode}
                    fieldConfig={{
                      name: 'paymentPeriodFrom',
                      label: 'Payment Period From',
                    }}
                    format="YYYY/MM/DD"
                    id="paymentPeriodFrom_field"
                    type="date"
                  />
                </Label>
                <Label data-testid="paymentPeriodTo_label" label="Payment Period To">
                  <FormSelectDateField
                    InputProps={
                      !writeMode
                        ? {
                            endAdornment: <IconButtonCopy<FormValues> field="paymentPeriodTo" />,
                          }
                        : undefined
                    }
                    data-testid="paymentPeriodTo_field"
                    disabled={!writeMode}
                    fieldConfig={{ name: 'paymentPeriodTo', label: 'Payment Period To' }}
                    format="YYYY/MM/DD"
                    id="paymentPeriodTo_field"
                    type="date"
                  />
                </Label>
                <Label data-testid="paymentPeriodTimezone_label" label="Timezone">
                  <FormTimezonePickerField
                    data-testid="paymentPeriodTimezone_field"
                    disabled={!writeMode}
                    fieldConfig={{ name: 'paymentPeriodTimezone' }}
                  />
                </Label>
              </Stack>
            </Stack>

            <Stack alignItems="start" direction="row" spacing={3}>
              <Label data-testid="nextPaymentDate_label" label="Next Payment Date">
                <FormSelectDateField
                  InputProps={
                    !writeMode
                      ? {
                          endAdornment: <IconButtonCopy<FormValues> field="nextPaymentDate" />,
                        }
                      : undefined
                  }
                  data-testid="nextPaymentDate_field"
                  disabled={!writeMode}
                  fieldConfig={{ name: 'nextPaymentDate' }}
                  format="YYYY/MM/DD"
                  id="nextPaymentDate_field"
                  type="datetime-local"
                />
              </Label>
              <Label data-testid="nextPaymentDateTimezone_label" label="Timezone">
                <FormTimezonePickerField
                  data-testid="nextPaymentDateTimezone_field"
                  disabled={!writeMode}
                  fieldConfig={{ name: 'nextPaymentDateTimezone' }}
                />
              </Label>
            </Stack>
          </Stack>
        </FormGroupContainer>

        <FormGroupContainer
          data-testid="client-payment-form-payment-details"
          title="Payment Details"
        >
          <Stack spacing={2}>
            <Stack alignItems="start" direction="row" spacing={3}>
              <Label data-testid="invoiceTime_label" label="Invoice Time">
                <FormSelectDateField
                  InputProps={
                    !writeMode
                      ? {
                          endAdornment: <IconButtonCopy<FormValues> field="invoiceTime" />,
                        }
                      : undefined
                  }
                  data-testid="invoiceTime_field"
                  disabled={!writeMode}
                  fieldConfig={{ name: 'invoiceTime' }}
                  format="YYYY/MM/DD"
                  helperText={`Kindly make payment before {date} to avoid any service disruption or late fees`} // TODO: replace with specific date
                  id="invoiceTime_field"
                  type="datetime-local"
                />
              </Label>
              <Label data-testid="invoiceTimeTimezone_label" label="Timezone">
                <FormTimezonePickerField
                  data-testid="invoiceTimeTimezone_field"
                  disabled={!writeMode}
                  fieldConfig={{ name: 'invoiceTimeTimezone' }}
                />
              </Label>
            </Stack>

            <Stack alignItems="start" direction="row" spacing={3}>
              <Label data-testid="paymentTime_label" label="Payment Time">
                <FormSelectDateField
                  InputProps={
                    !writeMode
                      ? {
                          endAdornment: <IconButtonCopy<FormValues> field="paymentTime" />,
                        }
                      : undefined
                  }
                  data-testid="paymentTime_field"
                  disabled={!writeMode}
                  fieldConfig={{ name: 'paymentTime' }}
                  format="YYYY/MM/DD"
                  id="paymentTime_field"
                  type="datetime-local"
                />
              </Label>
              <Label data-testid="paymentTimeTimezone_label" label="Timezone">
                <FormTimezonePickerField
                  data-testid="paymentTimeTimezone_field"
                  disabled={!writeMode}
                  fieldConfig={{ name: 'paymentTimeTimezone' }}
                />
              </Label>
            </Stack>

            <Label data-testid="paymentTxhash_label" label="Payment Transaction Hash">
              <FormTextFieldBase<FormValues>
                InputProps={
                  !writeMode
                    ? {
                        endAdornment: <IconButtonCopy<FormValues> field="paymentTxhash" />,
                      }
                    : undefined
                }
                data-testid="paymentTxhash_field"
                disabled={!writeMode}
                fieldConfig={{ name: 'paymentTxhash' }}
                id="paymentTxhash_field"
              />
            </Label>
          </Stack>
        </FormGroupContainer>

        <Stack alignItems="flex-end">
          <LoadingButton
            data-testid="submit-button"
            disabled={!writeMode}
            id="submit-button"
            loading={submitButtonLoading}
            type="submit"
            variant="contained"
          >
            {submitButtonLabelText}
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
