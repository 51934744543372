import {
  BoxProps,
  Grid,
  GridProps,
  IconButton,
  Stack,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import IconLeftArrow from './icons/IconLeftArrow';
import { SubHeader } from './SubHeader';

export interface ISection extends Omit<GridProps, 'children'> {
  title?: string;
  children?: ReactNode;
  hasBackButton?: boolean;
  renderSubHeaderStartContent?: ReactNode;
  renderSubHeaderEndContent?: ReactNode;
  renderGridContent?: ReactNode;
  renderContent?: ReactNode;
  renderTitleSuffix?: ReactNode;
  sectionContentSx?: GridProps['sx'];
  subHeaderSx?: BoxProps['sx'];
  titleTypographySx?: TypographyProps['sx'];
  titleTypographyVariant?: TypographyProps['variant'];
}

export const Section = ({
  children,
  hasBackButton,
  renderContent,
  renderGridContent,
  renderSubHeaderEndContent,
  renderSubHeaderStartContent,
  renderTitleSuffix,
  sectionContentSx,
  subHeaderSx,
  sx,
  title,
  titleTypographySx,
  titleTypographyVariant,
  ...props
}: ISection) => {
  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <Grid component="section" item sx={{ background: '#fff', width: '100%', ...sx }} {...props}>
      <SubHeader color={theme.colors.gradients.metal} sx={{ width: '100%', ...subHeaderSx }}>
        <Stack
          alignItems="center"
          direction="row"
          display="flex"
          justifyContent="space-between"
          sx={{ height: '100%' }}
        >
          {renderSubHeaderStartContent || (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="start"
              spacing={1}
              sx={{ width: '100%' }}
            >
              {hasBackButton && (
                <IconButton data-testid="section-back-button" onClick={() => navigate(-1)}>
                  <IconLeftArrow />
                </IconButton>
              )}
              <Typography
                data-testid="section-title"
                sx={titleTypographySx}
                variant={titleTypographyVariant ?? 'bodySmallC'}
              >
                {title}
              </Typography>
              {renderTitleSuffix}
            </Stack>
          )}
          {renderSubHeaderEndContent}
        </Stack>
      </SubHeader>
      {children || renderContent || (
        <Grid
          container
          sx={{
            px: { md: 5, sm: 3, xs: 3 },
            pt: { md: 3 },
            background: '#fff',
            ...sectionContentSx,
          }}
        >
          {renderGridContent}
        </Grid>
      )}
    </Grid>
  );
};
