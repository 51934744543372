import { IconButton, Stack, StackProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { IconClose } from 'shared/components/icons/IconClose';

interface IDialogHeader extends StackProps {
  title?: string;
  onClose?: () => void;
  startAdornment?: ReactNode;
}

export const DialogHeader: React.FC<IDialogHeader> = ({
  onClose,
  startAdornment,
  title,
  ...props
}) => {
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between" {...props}>
      {startAdornment || (
        <Typography
          data-testid="dialog-header-title"
          sx={{ fontSize: { xs: '18px', sm: '24px' } }}
          variant="h5"
        >
          {title}
        </Typography>
      )}

      <IconButton data-testid="dialog-close-button" onClick={() => onClose?.()} size="small">
        <IconClose />
      </IconButton>
    </Stack>
  );
};
