import { GetComponentStyleFunc } from './types';

export const muiChip: GetComponentStyleFunc<'MuiChip'> = _theme => {
  return {
    styleOverrides: {
      root: {
        borderRadius: 0,
      },
    },
  };
};
