import { AdminLayout } from 'pages/admin/layout';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { AdminGuard } from 'shared/guards';

import { routes as manageClientsRoutes } from './ManageClients/routes';
import RaasAdmin from './RaasAdmin/RaasAdmin';
import { routes as adminRaasRoutes } from './RaasAdmin/routes';
import ManageAgentRequests from './ManageAgentRequests';
import ManageFinetuneSources from './ManageFinetuneSources';
import ManagePromoCodes from './ManagePromoCodes';

export enum ADMIN_PATHS {
  ADMIN = '/admin',
  ADMIN_RAAS = '/admin/raas',
  MANAGE_PROMO_CODES = '/admin/promo-codes',
  MANAGE_AGENT_REQUESTS = '/admin/publish-agent-requests',
  MANAGE_FINETUNE_SOURCES = '/admin/manage-finetune-sources',
  MANAGE_CLIENTS = '/admin/manage-clients',
  MANAGE_CLIENTS_DETAILS = '/admin/manage-clients/:clientId',
  MANAGE_CLIENTS_CREATE = '/admin/manage-clients/create',
  MANAGE_CLIENTS_CREATE_PAYMENT = '/admin/manage-clients/:clientId/payments/create',
  MANAGE_CLIENTS_PAYMENT_DETAILS = '/admin/manage-clients/:clientId/payments/:paymentId',
}

export const routes: RouteObject[] = [
  {
    path: ADMIN_PATHS.ADMIN,
    element: (
      <AdminGuard>
        <AdminLayout />
      </AdminGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to={ADMIN_PATHS.MANAGE_PROMO_CODES} />,
      },
      {
        path: 'raas',
        element: <RaasAdmin />,
        children: [...adminRaasRoutes],
      },
      {
        element: <ManagePromoCodes />,
        path: 'promo-codes',
      },
      {
        element: <ManageAgentRequests />,
        path: 'publish-agent-requests',
      },
      {
        element: <ManageFinetuneSources />,
        path: 'manage-finetune-sources',
      },
      {
        element: <Outlet />,
        path: 'manage-clients',
        children: manageClientsRoutes,
      },
    ],
  },
];
