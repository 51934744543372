import { BoxProps, Grid, Stack, Typography } from '@mui/material';
import {
  AgentCarouselCard,
  AgentCarouselCardProps,
} from 'pages/aaas/AaasDeployments/components/AgentFrameworksCarousel/AgentCarouselCard';
import { SwiperCarousel } from 'pages/aaas/components/SwiperCarousel';
import { FC, useMemo } from 'react';
import { partitionArray } from 'shared/utils/array';
import { SwiperSlide } from 'swiper/react';

interface IAgentFrameworksSwiperCarousel extends Omit<BoxProps, 'children'> {
  cards: AgentCarouselCardProps[];
  emptyDataText?: string;
}

export const AgentFrameworksSwiperCarousel: FC<IAgentFrameworksSwiperCarousel> = ({
  cards,
  emptyDataText = 'No templates found',
  ...props
}) => {
  const cardsPerSlide = useMemo(() => {
    if (cards.length < 12) return 3;
    if (cards.length >= 12 && cards.length < 18) return 6;
    if (cards.length >= 18) return 9;

    return 3;
  }, [cards]);

  const partitioned = useMemo(() => partitionArray(cards, cardsPerSlide), [cards, cardsPerSlide]);

  return (
    <>
      {partitioned.length > 0 ? (
        <SwiperCarousel
          py={3}
          slides={partitioned.map((templates, pIdx) => {
            return (
              <SwiperSlide key={pIdx.toString()}>
                <Grid container spacing={{ md: 3, xs: 2 }}>
                  {templates.map((card, index) => (
                    <Grid item key={index} lg={4} md={6} sm={12} xl={4} xs={12}>
                      <AgentCarouselCard card={card} />
                    </Grid>
                  ))}
                </Grid>
              </SwiperSlide>
            );
          })}
          {...props}
        />
      ) : (
        <Stack alignItems="center" height="100%" justifyContent="center" py={16}>
          <Typography variant="bodySmall">{emptyDataText}</Typography>
        </Stack>
      )}
    </>
  );
};
