import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { getBasicAuthHeaders } from 'pages/aaas/AaasDetails/utils';
import { useState } from 'react';
import {
  AAATemplateCategory,
  aAATemplateCategoryFromJSON,
  AAATemplateHostType,
  aAATemplateHostTypeToJSON,
} from 'shared/types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'shared/types/react-query';

import { PromptReply, useApp, useChatEndpoint } from '.';

interface IUseHealthz extends Omit<UseQueryOptions, 'queryFn' | 'queryKey'> {
  appId?: string;
}

export const useHealthz = (opts?: IUseHealthz) => {
  const [throwCount, setThrowCount] = useState<number>(0);
  const { data: app } = useApp({ appId: opts?.appId });
  const { data: chatEndpoint } = useChatEndpoint({ app });

  const { addAlert } = useAlerts();

  return useQuery({
    queryKey: [QUERY_KEYS.AAAS_HEALTHZ, { appId: opts?.appId }],
    queryFn: async (): Promise<PromptReply | null> => {
      if (!app?.endpoints?.apiUrl || !chatEndpoint) return null;

      try {
        if (
          String(app?.template?.type) ===
          aAATemplateHostTypeToJSON(AAATemplateHostType.AAA_TEMPLATE_HOST_TYPE_CHATBOX)
        ) {
          const res = await axios.post<PromptReply>(
            chatEndpoint,
            ['eliza', 'elizabnb'].includes(app.template?.id.toLowerCase() || '') ||
              aAATemplateCategoryFromJSON(app?.template?.category) ===
                AAATemplateCategory.AAA_TEMPLATE_TYPE_APP_STORE
              ? { text: 'aaa' }
              : { message: 'healthz' },
            {
              headers: getBasicAuthHeaders(app),
            },
          );

          return res.data;
        }

        const res = await axios.get<PromptReply>(chatEndpoint);

        return res.data;
      } catch (err: any) {
        setThrowCount(throwCount + 1);

        if (
          throwCount === 3 &&
          app?.template?.type === AAATemplateHostType.AAA_TEMPLATE_HOST_TYPE_CHATBOX &&
          err.name === 'AxiosError' &&
          err?.code === 'ERR_NETWORK' &&
          err?.config?.method === 'post'
        )
          addAlert({
            severity: ALERT_SEVERITY.INFO,
            title: 'AI Agent Not Available',
            desc: 'It seems like the AI agent is taking awhile to deploy. Please refresh the page and try again in a few minutes.',
          });

        throw err;
      }
    },
    enabled: !!chatEndpoint && !!app?.endpoints?.apiUrl,
    retry: 24,
    retryDelay: 15_000,
    ...opts,
  });
};
