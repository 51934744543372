import { FormSelectItem } from 'shared/components/form';

import { DeploymentType } from './types';

export const deploymentTypeHelperText = {
  [DeploymentType.TEE]:
    'Verifiable agents run in a Trusted Execution Environment (TEE) such as Intel SGX, Intel TDX, AWS Nitro Enclave and AMD SEV. Such agents guarantee non human/machine interference and generate verifiable proofs of correct execution.',
  [DeploymentType.NON_TEE]:
    'Non-verifiable agents run on standard hardware and do not provide any guarantees of non human/machine interference.',
};

export const envVarHelpMarkdown = {
  OPENAI: `## Help: Where do I find my OpenAI API Key?

  You can find your Secret API key on the [API key page](https://platform.openai.com/api-keys).

  Check out our [Best Practices for API Key Safety](https://help.openai.com/en/articles/5112595) to learn how you can keep your API key protected.

  _*Reminder: Do not share you API key with anyone!*_

  See more information on the [OpenAI API Key page](https://help.openai.com/en/articles/4936850-where-do-i-find-my-openai-api-key).
  `,
  CDP: `## Help
  Go to [docs.cdp.coinbase.com](https://docs.cdp.coinbase.com/coinbase-app/docs/getting-started) for up-to-date information.

  After creating an API key, can be obtained from the dialog that pops up after creation.
  
  ![dialog popup censored](/imgs/cdp-secrets-censored.png)
  `,
};

export const ENV_VARS_PLACEHOLDER_TEXT: Record<string, string> = {
  NETWORK_ID: 'Select a network ID',
};

export const ENV_VARS_HELP_TEXT: Record<string, string> = {
  OPENAI_API_KEY: 'Free tier API keys are not supported by this template.',
  OPENAIKEY: 'Free tier API keys are not supported by this template.',
};

export const ENV_VARS_FORM_MULTILINE_FIELDS = ['CDP_PRIVATE_KEY', 'CDP_API_KEY_PRIVATE_KEY'];

export const ENV_VARS_FORM_SELECT_FIELDS = ['NETWORK_ID'];

export const ENV_VARS_OPTIONS: Record<string, FormSelectItem[]> = {
  NETWORK_ID: [
    { value: 'base-mainnet', label: 'Base Mainnet' },
    { value: 'base-sepolia', label: 'Base Sepolia' },
  ],
};

export const ENV_VARS_FORM_NUMERIC_FIELDS = ['POST_INTERVAL_MIN', 'POST_INTERVAL_MAX'];

export const ENV_VARS_FORM_NON_SENSITIVE_FIELDS = [
  'BNB_PUBLIC_KEY',
  'BSC_PROVIDER_URL',
  'POST_INTERVAL_MIN',
  'POST_INTERVAL_MAX',
  'TWITTER_USERNAME',
  'TWITTER_EMAIL',
  'NETWORK_ID',
];
