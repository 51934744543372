import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogProps, Stack, Typography } from '@mui/material';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { FC, useCallback, useEffect } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { CustomAutocomplete } from 'shared/components/CustomAutocomplete';
import { DialogContainer, DialogHeader } from 'shared/components/Dialog';
import { Label } from 'shared/components/form';
import * as yup from 'yup';

import { useFineTune } from './useFinetune';

interface FormValues {
  telegramChannels: string[];
  twitterHandles: string[];
}

const schema = yup.object().shape({
  telegramChannels: yup.array().of(yup.string().required()),
  twitterHandles: yup.array().of(yup.string().required()),
});

export const FinetuneDialog: FC<DialogProps> = ({ onClose, ...props }) => {
  const { addAlert } = useAlerts();

  const form = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(schema as yup.ObjectSchema<FormValues>),
  });

  const formValues = form.watch();

  const { isLoadingSources, isUpdating, jobs, telegramOptions, twitterOptions, updateJob } =
    useFineTune();

  useEffect(() => {
    if (!formValues.telegramChannels && !formValues.twitterHandles && jobs) {
      form.setValue('twitterHandles', jobs.twittersProfiles);
      form.setValue('telegramChannels', jobs.telegramChannelIds);
    }
  }, [form, formValues.telegramChannels, formValues.twitterHandles, jobs]);

  const handleClose = useCallback(() => onClose?.({}, 'backdropClick'), [onClose]);

  const onInvalid: SubmitErrorHandler<FormValues> = useCallback(
    errors => {
      console.error('errors', errors);
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Invalid form',
        desc: 'Please fill out the required fields',
      });
    },
    [addAlert],
  );

  const onSubmit: SubmitHandler<FormValues> = data => {
    console.log('form data: ', data);
    console.log('Submitting');
    updateJob({
      twittersProfiles: data.twitterHandles,
      telegramChannelIds: data.telegramChannels,
    });
  };

  const hasChanges =
    formValues.telegramChannels?.join(',') !== jobs?.telegramChannelIds?.join(',') ||
    formValues.twitterHandles?.join(',') !== jobs?.twittersProfiles?.join(',');

  return (
    <Dialog onClose={onClose} {...props}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit, onInvalid)}>
          <DialogContainer>
            <DialogHeader
              alignItems="start"
              onClose={handleClose}
              startAdornment={
                <Stack>
                  <Typography variant="h5">{'Finetune Agent'}</Typography>
                  <Typography variant="caption">
                    Periodically feed your agent data from these sources
                  </Typography>
                </Stack>
              }
            />
            <Stack spacing={3}>
              <Label
                label="Select Twitter accounts"
                subLabel="1 tweet per hour based on the fine-tune dataset"
              >
                <CustomAutocomplete
                  afterChange={selected =>
                    form.setValue(
                      'twitterHandles',
                      selected.map(cur => String(cur.value)),
                    )
                  }
                  errorMsg={form.formState.errors.twitterHandles?.message}
                  loading={isLoadingSources}
                  multiple
                  options={twitterOptions || []}
                  placeholder="Select Twitter accounts"
                  value={
                    twitterOptions?.filter(cur =>
                      formValues.twitterHandles?.includes(cur?.value),
                    ) || null
                  }
                />
              </Label>
              <Label label="Select Telegram channels">
                <CustomAutocomplete
                  afterChange={selected =>
                    form.setValue(
                      'telegramChannels',
                      selected.map(cur => String(cur.value)),
                    )
                  }
                  errorMsg={form.formState.errors.telegramChannels?.message}
                  fullWidth
                  loading={isLoadingSources}
                  multiple
                  options={telegramOptions || []}
                  placeholder="Select Telegram channels"
                  value={
                    telegramOptions?.filter(cur =>
                      formValues.telegramChannels?.includes(cur?.value),
                    ) || null
                  }
                />
              </Label>

              <LoadingButton
                disabled={!hasChanges}
                fullWidth
                loading={isUpdating}
                type="submit"
                variant="contained"
              >
                <Typography variant="body1">Update</Typography>
              </LoadingButton>
            </Stack>
          </DialogContainer>
        </form>
      </FormProvider>
    </Dialog>
  );
};
