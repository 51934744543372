import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useCallback, useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { raas } from 'shared/api';
import { AAATemplateHostType, CreateAAATemplateRequest } from 'shared/types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'shared/types/react-query';

import { FormValues } from './types';

const DEFAULT_JSON_SCHEMA = {
  type: 'object',
  properties: {
    text: {
      type: 'string',
    },
  },
};

export default function usePublishAgent() {
  const { addAlert } = useAlerts();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: publishAgent, ...mutationReturn } = useMutation({
    mutationFn: async (reqBody: CreateAAATemplateRequest) => {
      await raas.post('/bff/aaa/templates', reqBody);
    },
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Agent listing request submitted successfully.',
      });
      queryClient?.invalidateQueries({ queryKey: [QUERY_KEYS.AAAS_GET_AAA_TEMPLATES] });
      navigate(PATHS.AAAS_DEPLOYMENTS);
    },
    onError: err => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title:
          (err as AxiosError<{ message: string }>)?.response?.data?.message ||
          'Failed to submit agent listing request.',
      });
    },
  });

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    data => {
      console.log('form values: ', data);
      publishAgent({
        name: String(data?.imageName),
        dockerImage: String(data?.dockerImg),
        type: AAATemplateHostType.AAA_TEMPLATE_HOST_TYPE_CHATBOX,
        chatSubpath: String(data?.chatEndpoint),
        description: String(data?.imageDesc),
        iconLink: String(data?.agentLogo),
        envList: data?.envVars?.map(cur => cur?.name) || [],
        configExample: '', // TODO: add config example field
        requestSchema: DEFAULT_JSON_SCHEMA,
        responseSchema: DEFAULT_JSON_SCHEMA,
        port: Number(data?.port),
      });
    },
    [publishAgent],
  );

  return useMemo(() => ({ publish: onSubmit, ...mutationReturn }), [mutationReturn, onSubmit]);
}
