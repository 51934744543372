import { useQuery } from '@tanstack/react-query';
import { useAgentId } from 'pages/aaas/AaasDetails/hooks/useAgentId';
import { useMemo } from 'react';
import {
  AAAApp,
  AAATemplateCategory,
  aAATemplateCategoryFromJSON,
} from 'shared/types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'shared/types/react-query';
import { constructEndpointSafe } from 'shared/utils/strings';

export interface IUseChatEndpoint {
  app?: AAAApp;
}

export const useChatEndpoint = (opts: IUseChatEndpoint) => {
  const { app } = opts;
  const { data: agentId } = useAgentId();
  const isEliza = ['eliza', 'elizabnb'].includes(app?.template?.id?.toLowerCase() || '');
  const requiresAgentIdInterpolation = app?.template?.chatSubpath?.includes('$agentId$');

  const readyToConstructEndpoint = useMemo(() => {
    if (!app?.endpoints?.apiUrl) return false;
    if (isEliza && agentId) return true;
    if (requiresAgentIdInterpolation && agentId) return true;
    if (!isEliza && !requiresAgentIdInterpolation) return true;

    return false;
  }, [agentId, app?.endpoints?.apiUrl, isEliza, requiresAgentIdInterpolation]);

  return useQuery({
    queryKey: [QUERY_KEYS.AAAS_GET_AAA_CHAT_ENDPOINT, { appId: app?.id }],
    queryFn: () => {
      if (!app?.endpoints?.apiUrl || (isEliza && !agentId)) return null;

      if (isEliza && !!agentId) {
        return constructEndpointSafe(app.endpoints.apiUrl, agentId, 'message');
      }

      if (requiresAgentIdInterpolation) {
        return constructEndpointSafe(
          app.endpoints.apiUrl,
          app.template?.chatSubpath?.replace('$agentId$', agentId || '') || 'chat',
        );
      }

      if (
        aAATemplateCategoryFromJSON(app?.template?.category) ===
        AAATemplateCategory.AAA_TEMPLATE_TYPE_APP_STORE
      ) {
        return constructEndpointSafe(app.endpoints.apiUrl, app.template?.chatSubpath || 'chat');
      }

      return constructEndpointSafe(app.endpoints.apiUrl, '/chat');
    },
    enabled: readyToConstructEndpoint,
  });
};
