import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { PaymentStatusChip } from 'pages/admin/ManageClients/PaymentStatusChip';
import { ADMIN_PATHS } from 'pages/admin/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { bff } from 'shared/api';
import { Section } from 'shared/components';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import { useDisclosure } from 'shared/hooks/ui';
import { DeleteAgreementReply } from 'shared/types/protoc-gen/bffbillsystem';
import { QUERY_KEYS } from 'shared/types/react-query';

interface IBillingSection {
  billingStatus: string | undefined;
}

export const BillingSection = ({ billingStatus }: IBillingSection) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { addAlert } = useAlerts();
  const { clientId } = useParams();
  const {
    onClose: onCloseDeleteConfirmationDialog,
    onOpen: onOpenDeleteConfirmationDialog,
    open: isOpenDeleteConfirmationDialog,
  } = useDisclosure();

  const { isPending: isDeleteClientPending, mutate: deleteClient } = useMutation({
    mutationFn: async () => {
      const res = await bff.delete<DeleteAgreementReply>(`/bff/bill/agreements/${clientId}`);

      return res.data;
    },
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Client deleted successfully',
        desc: 'The client has been deleted successfully',
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ADMIN_CLIENT_LIST] });
      navigate(ADMIN_PATHS.MANAGE_CLIENTS, { replace: true });
    },
    onError: () => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Error',
        desc: 'An error occurred while deleting the client',
      });
    },
  });

  return (
    <>
      <ConfirmationDialog
        handleClose={onCloseDeleteConfirmationDialog}
        isLoading={isDeleteClientPending}
        onClose={onCloseDeleteConfirmationDialog}
        onConfirm={() => {
          onCloseDeleteConfirmationDialog();
          deleteClient();
        }}
        open={isOpenDeleteConfirmationDialog}
        title="Delete Client"
      >
        <Typography>Are you sure you want to delete this client?</Typography>
      </ConfirmationDialog>

      <Section data-testid="billing-section" title="Billing">
        <Stack px={5} py={3} spacing={3} width="100%">
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Typography variant="bodySmallM">Billing status</Typography>
            {billingStatus ? <PaymentStatusChip status={billingStatus} /> : 'n/a'}
          </Stack>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Typography variant="bodySmallM">Danger zone</Typography>
            <LoadingButton
              id="deleteClientButton"
              loading={isDeleteClientPending}
              onClick={onOpenDeleteConfirmationDialog}
              sx={{
                '&,&:hover': { bgcolor: theme => theme.colors.schema.error },
                '&,&.MuiLoadingButton-loading': {
                  bgcolor: theme => `${theme.colors.schema.error} !important`,
                },
              }}
              variant="contained"
            >
              Delete Client
            </LoadingButton>
          </Stack>
        </Stack>
      </Section>
    </>
  );
};
