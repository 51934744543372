import { toRegex } from 'shared/utils/strings';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  imageName: yup
    .string()
    .required()
    .noUrl()
    .min(3)
    .max(12)
    .matches(/^[0-9a-z-]+$/, "Please use lowercase alphanumeric characters or '-' only.")
    .matches(/^[^-].*[^-]$/, "Cannot begin or end with '-'.")
    .matches(/^(?!.*-{2}).*$/, "'-' cannot be used consecutively.")
    .matches(/[a-z0-9]([-a-z0-9]*[a-z0-9])/, 'Does not match regex [a-z0-9]([-a-z0-9]*[a-z0-9])')
    .noLeadingDigit(),
  imageDesc: yup.string().required().min(5).max(300),
  agentLogo: yup
    .string()
    .required()
    .url()
    .test('image-accessible', 'Image cannot be accessed or is invalid', value => {
      if (!value) return false;

      return new Promise(resolve => {
        const img = new Image();

        img.src = value;
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      });
    }),
  chatEndpoint: yup
    .string()
    .required()
    .matches(/^\/.*$/, "Must begin with '/'")
    .noUrl('Only the path is required. E.g. https://example.com/ping -> /ping'),
  dockerImg: yup.string().required().isDockerImg(),
  githubUrl: yup.string().url(),
  port: yup.number().required().integer().min(1).max(65535),
  envVars: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Name is required'),
      regex: yup.string().isRegex().optional(),
      example: yup.string().when('regex', ([regexStrVal], schema) => {
        if (!regexStrVal) return schema.optional();
        const regex = toRegex(regexStrVal);

        return regex
          ? schema
              .required('Example value is required when regex is specified')
              .matches(regex, 'Example value does not match regex')
          : schema.required('Example value is required when regex is specified');
      }),
    }),
  ),
});
