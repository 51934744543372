import { array, number, object, string } from 'yup';

export const basicInfoRules = {
  clientName: string().required(),
  clientNameInAgreement: string().required(),
  contactEmails: string()
    .required()
    .test('valid-emails', 'Must contain valid email(s) separated by commas', value => {
      if (!value) return false;

      return value
        .split(',')
        .map(e => e.trim())
        .every(email => string().email(`Invalid email format: ${email}`).isValidSync(email));
    }),
  bindingChains: array()
    .of(
      object().shape({
        chainId: string().required(),
        launchingDate: string().required().validDate(),
        shutdownDate: string().optional().validDate(),
      }),
    )
    .optional(),
};

export const agreementInfoRules = {
  agreementLink: string().url().required(),
  agreementStatus: string()
    .required()
    .oneOf(
      ['Active', 'Pause', 'Terminated'],
      'Agreement Status must be one of: Active, Pause, Terminated',
    ),
  agreementScope: number()
    .required('Agreement Scope is required')
    .oneOf([1, 2, 3], 'Agreement Scope must be one of: 1, 2, 3'),
  paymentFrequency: string()
    .required()
    .oneOf(
      ['Monthly', 'Quarterly', 'Yearly'],
      'Payment Frequency must be one of: Monthly, Quarterly, Yearly',
    ),
  amount: number().required(),
  notes: string().optional(),
};

export const ClientPaymentFormSchema = object().shape({
  type: string()
    .oneOf(
      ['Other fee', 'Service fee', 'Withdraw fee'],
      'Invalid type must be one of: Other fee, Service fee, Withdraw fee',
    )
    .required(),
  subType: string().when('type', ([type]) => {
    if (type === 'Withdraw fee' || type === 'Service fee') {
      return string().optional();
    }

    return string().required();
  }),
  amount: number().required(),
  currency: string()
    .oneOf(['USDT', 'ETH', 'BNB', 'USDC'], 'Invalid currency must be one of: USDT, ETH, BNB, USDC')
    .required(),
  status: string()
    .oneOf(
      ['Awaiting', 'Paid', 'Overdue', 'PaidAfterOverdue'],
      'Invalid status must be one of: Awaiting, Paid, Overdue, PaidAfterOverdue',
    )
    .required(),
  paymentPeriodFrom: string().required().validDate(),
  paymentPeriodTo: string().required().validDate(),
  nextPaymentDate: string().required().validDate(),
  invoiceTime: string().required().validDate(),
  paymentTime: string().when('status', ([status]) => {
    if (status === 'Awaiting' || status === 'Overdue') {
      return string().optional();
    }

    return string().required();
  }),
  paymentTxhash: string().when('status', ([status]) => {
    if (status === 'Awaiting' || status === 'Overdue') {
      return string()
        .optional()
        .matches(
          /^$|^0x[a-fA-F0-9]{64}$/,
          'Must be a valid transaction hash (0x followed by 64 hexadecimal characters)',
        );
    }

    return string()
      .required()
      .matches(
        /^$|^0x[a-fA-F0-9]{64}$/,
        'Must be a valid transaction hash (0x followed by 64 hexadecimal characters)',
      );
  }),
});

export const BasicInfoFormSchema = object().shape({
  ...basicInfoRules,
});

export const AgreementInfoFormSchema = object().shape({
  ...agreementInfoRules,
});

export const CreateClientFormSchema = object().shape({
  ...basicInfoRules,
  ...agreementInfoRules,
});
