import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import dayjs from 'dayjs';
import { AgreementInfoFormGroup } from 'pages/admin/ManageClients/AgreementInfoFormGroup';
import { BasicInfoFormGroup } from 'pages/admin/ManageClients/BasicInfoFormGroup';
import { AgreementInfoFormValues, BasicInfoFormValues } from 'pages/admin/ManageClients/types';
import { useGetClient } from 'pages/admin/ManageClients/useGetClient';
import { AgreementInfoFormSchema, BasicInfoFormSchema } from 'pages/admin/ManageClients/validation';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { bff } from 'shared/api';
import { Section } from 'shared/components';
import {
  EditAgreementClientReply,
  EditAgreementClientRequest,
  EditAgreementDetailsReply,
  EditAgreementDetailsRequest,
} from 'shared/types/protoc-gen/bffbillsystem';
import { MUTATION_KEYS, QUERY_KEYS } from 'shared/types/react-query';
import { ObjectSchema } from 'yup';

import { BillingSection } from './BillingSection';
import { PaymentListSection } from './PaymentListSection';
import { PaymentTrackerSection } from './PaymentTrackerSection';

export const ClientDetailsSubView = () => {
  const queryClient = useQueryClient();

  const { addAlert } = useAlerts();
  const { clientId } = useParams();

  const [basicInfoEditMode, setBasicInfoEditMode] = useState<boolean>(false);
  const [agreementInfoEditMode, setAgreementInfoEditMode] = useState<boolean>(false);

  const { data: client, isPending: getClientPending } = useGetClient(Number(clientId));

  const basicInfoForm = useForm<BasicInfoFormValues>({
    mode: 'all',
    resolver: yupResolver<BasicInfoFormValues>(
      BasicInfoFormSchema as unknown as ObjectSchema<BasicInfoFormValues>,
    ),
  });
  const agreementInfoForm = useForm<AgreementInfoFormValues>({
    mode: 'all',
    defaultValues: {
      agreementLink: '',
      agreementStatus: '',
      agreementScope: 0,
      paymentFrequency: '',
      amount: 0,
      notes: '',
    },
    resolver: yupResolver<AgreementInfoFormValues>(
      AgreementInfoFormSchema as unknown as ObjectSchema<AgreementInfoFormValues>,
    ),
  });

  useEffect(() => {
    if (client) {
      basicInfoForm.reset({
        clientName: client.clientName,
        clientNameInAgreement: client.clientNameInAgreement,
        contactEmails: client.contactEmails,
        bindingChains: client.bindingChains.map(bindingChain => ({
          chainId: bindingChain.chainId,
          launchingDate: dayjs(bindingChain.launchingDate),
          shutdownDate: bindingChain.shutdownDate ? dayjs(bindingChain.shutdownDate) : undefined,
        })),
      });
      agreementInfoForm.reset({
        agreementLink: client.agreementLink,
        agreementStatus: client.agreementStatus,
        agreementScope: client.agreementScope,
        paymentFrequency: client.paymentFrequency,
        amount: client.amount,
        notes: client.notes,
      });
    }
  }, [agreementInfoForm, basicInfoForm, client]);

  const { isPending: isUpdateBasicInfoPending, mutate: updateBasicInfo } = useMutation({
    mutationKey: [MUTATION_KEYS.ADMIN_CLIENT_UPDATE_BASIC_INFO, { clientId }],
    mutationFn: async (data: Omit<EditAgreementClientRequest, 'agreementId'>) => {
      const res = await bff.put<EditAgreementClientReply>(`bff/bill/agreements/client`, {
        agreementId: Number(clientId),
        ...data,
      });

      return res.data;
    },
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Basic info updated successfully',
        desc: 'The basic info has been updated successfully',
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ADMIN_CLIENT_DETAILS, { clientId }] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ADMIN_CLIENT_LIST] });
    },
    onError: () => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Failed to update basic info',
        desc: 'Please try again',
      });
    },
  });
  const { isPending: isUpdateAgreementInfoPending, mutate: updateAgreementInfo } = useMutation({
    mutationKey: [MUTATION_KEYS.ADMIN_CLIENT_UPDATE_AGREEMENT_INFO, { clientId }],
    mutationFn: async (data: Omit<EditAgreementDetailsRequest, 'agreementId'>) => {
      const res = await bff.put<EditAgreementDetailsReply>(`bff/bill/agreements/details`, {
        agreementId: Number(clientId),
        ...data,
      });

      return res.data;
    },
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Agreement info updated successfully',
        desc: 'The agreement info has been updated successfully',
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ADMIN_CLIENT_DETAILS, { clientId }] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ADMIN_CLIENT_LIST] });
    },
    onError: () => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Failed to update agreement info',
        desc: 'Please try again',
      });
    },
  });

  return (
    <>
      <Section
        hasBackButton
        renderSubHeaderStartContent={getClientPending ? <CircularProgress size={24} /> : undefined}
        title={client?.clientName}
      >
        <Stack px={5} py={3} spacing={5} sx={{ bgcolor: '#fff' }}>
          <FormProvider {...basicInfoForm}>
            <BasicInfoFormGroup
              component="form"
              endAdornment={
                <Stack direction="row" spacing={2}>
                  <Button
                    data-testid="basic-info-edit-button"
                    disabled={getClientPending || basicInfoEditMode}
                    onClick={() => setBasicInfoEditMode(prev => !prev)}
                    size="small"
                  >
                    Edit
                  </Button>
                  <LoadingButton
                    data-testid="basic-info-save-button"
                    disabled={getClientPending || !basicInfoEditMode}
                    loading={isUpdateBasicInfoPending}
                    size="small"
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </LoadingButton>
                </Stack>
              }
              includeBindingChains
              onSubmit={basicInfoForm.handleSubmit(
                formValues => {
                  console.log('basicInfo.formValues:', formValues);
                  updateBasicInfo({
                    ...formValues,
                    bindingChains: JSON.stringify(
                      formValues.bindingChains?.map(bindingChain => ({
                        chain_id: bindingChain?.chainId ?? '',
                        launching_date: bindingChain.launchingDate
                          ? dayjs(bindingChain.launchingDate).format('YYYY-MM-DD HH:mm:ss')
                          : undefined,
                        shutdown_date: bindingChain.shutdownDate
                          ? dayjs(bindingChain.shutdownDate).format('YYYY-MM-DD HH:mm:ss')
                          : undefined,
                      })) ?? [],
                    ),
                  });
                },
                err => {
                  console.log('basicInfo.err:', err);
                  addAlert({
                    severity: ALERT_SEVERITY.ERROR,
                    title: 'Failed to save basic info',
                    desc: 'Please try again',
                  });
                },
              )}
              writeMode={basicInfoEditMode}
            />
          </FormProvider>
          <FormProvider {...agreementInfoForm}>
            <AgreementInfoFormGroup
              component="form"
              endAdornment={
                <Stack direction="row" spacing={2}>
                  <Button
                    data-testid="agreement-info-edit-button"
                    disabled={getClientPending || agreementInfoEditMode}
                    onClick={() => setAgreementInfoEditMode(prev => !prev)}
                    size="small"
                  >
                    Edit
                  </Button>
                  <LoadingButton
                    data-testid="agreement-info-save-button"
                    disabled={getClientPending || !agreementInfoEditMode}
                    loading={isUpdateAgreementInfoPending}
                    size="small"
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </LoadingButton>
                </Stack>
              }
              onSubmit={agreementInfoForm.handleSubmit(
                formValues => {
                  console.log('agreementInfo.formValues:', formValues);
                  updateAgreementInfo(formValues);
                },
                err => {
                  console.log('agreementInfo.err:', err);
                  addAlert({
                    severity: ALERT_SEVERITY.ERROR,
                    title: 'Failed to save agreement info',
                    desc: 'Please try again',
                  });
                },
              )}
              writeMode={agreementInfoEditMode}
            />
          </FormProvider>
        </Stack>
      </Section>
      <PaymentTrackerSection />
      <PaymentListSection />
      <BillingSection billingStatus={client?.billingStatus} />
    </>
  );
};
