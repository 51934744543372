import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Editor } from '@monaco-editor/react';
import { Box, Grid } from '@mui/material';
import { AaasLayout } from 'pages/aaas/layout';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { FormTextField } from 'shared/components/form';
import InlineCode from 'shared/components/Formatted/InlineCode';
import { PageBannerVariant } from 'shared/components/Layout/PageBanner';
import { Option } from 'shared/components/Option';
import { SectionTitle } from 'shared/components/SectionTitle';
import { Info } from 'shared/components/WithInfo';
import * as yup from 'yup';

import EnvVarsFieldArray from './EnvVarsFieldArray';
import { PublishAgentSummaryPanel } from './PublishAgentSummaryPanel';
import { FormValues } from './types';
import usePublishAgent from './usePublishAgent';
import { validationSchema } from './validation';

const DEFAULT_REQUEST_PAYLOAD = JSON.stringify({ text: 'Your prompt here' }, undefined, 2);
const DEFAULT_RESPONSE_PAYLOAD = JSON.stringify(
  { text: "Your agent's response here" },
  undefined,
  2,
);

export const PublishCommunityAgent = () => {
  const navigate = useNavigate();
  const form = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      imageName: '',
      imageDesc: '',
      dockerImg: '',
      agentLogo: '',
      port: undefined,
      githubUrl: '',
    },
    resolver: yupResolver(validationSchema as yup.ObjectSchema<FormValues>),
  });

  const { isPending: isPendingPublish, publish } = usePublishAgent();

  return (
    <>
      <AaasLayout
        pageBannerProps={{
          onClickBack: () => navigate(PATHS.AAAS_DEPLOYMENTS, { replace: true }),
          title: 'Upload your framework',
          variant: PageBannerVariant.AAAS,
        }}
      >
        <Box bgcolor="#fafafa" pb={4} sx={{ position: 'relative' }}>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(publish, err => console.error('err:', err))}>
              <Box margin="0 auto">
                <Grid container>
                  <Grid
                    item
                    md={8}
                    sx={{
                      '& > *:not(:first-child)': {
                        mr: {
                          xs: 3,
                          sm: 3,
                          md: 0,
                        },
                      },
                    }}
                    xs={12}
                  >
                    <SectionTitle>Agent framework metadata</SectionTitle>
                    <FormTextField
                      fieldConfig={{
                        name: 'imageName',
                        label: 'Name',
                        placeholder: 'agent-ottie-007',
                      }}
                      id="step_imageName"
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'imageDesc',
                        label: 'Description',
                        placeholder:
                          'A sophisticated furry chat agent specializing in handling mission-critical conversations.',
                      }}
                      id="step_imageDesc"
                      maxRows={10}
                      minRows={3}
                      multiline
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'dockerImg',
                        label: 'Docker Image',
                        placeholder: 'dockerhub_org_name/image_name:tag',
                      }}
                      id="step_dockerImg"
                      tooltipContent={
                        <>
                          Ensure that the target platform is <InlineCode>linux/amd64</InlineCode>.
                          On macOS (or when cross-compiling), use{' '}
                          <InlineCode>docker buildx build --platform=linux/amd64</InlineCode> to
                          build accordingly.
                        </>
                      }
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'agentLogo',
                        label: 'Agent logo',
                        placeholder: 'https://example.com/ottie007.png',
                      }}
                      id="step_agentLogo"
                      tooltipContent="Please ensure that your logo url is publicly accessible."
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'port',
                        label: 'Port',
                        placeholder: '3000',
                      }}
                      id="step_port"
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'chatEndpoint',
                        label: 'Chat endpoint',
                        placeholder: '/chat',
                      }}
                      id="step_chatEndpoint"
                      tooltipContent="Please ensure your chat endpoint is accessible at this path. For Eliza forks, an interpolation variable $agentId$ is exposed to construct your dynamic chat endpoint (e.g. /$agentId$/message)"
                    />
                    <Grid container pr={{ md: 0, xs: 3 }}>
                      <Grid item md={6} xs={12}>
                        <Option
                          endAdornment={
                            <Info info="Please ensure your request conforms to this payload" />
                          }
                          optionTitle="Chat request payload"
                        >
                          <Editor
                            defaultLanguage="json"
                            height="5rem"
                            options={{
                              scrollBeyondLastLine: false,
                              readOnly: true,
                            }}
                            theme="vs-dark"
                            value={DEFAULT_REQUEST_PAYLOAD}
                          />
                        </Option>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Option
                          endAdornment={
                            <Info info="Please ensure your agent's response conforms to this payload" />
                          }
                          optionTitle="Chat response payload"
                        >
                          <Editor
                            defaultLanguage="json"
                            height="5rem"
                            options={{
                              scrollBeyondLastLine: false,
                              readOnly: true,
                            }}
                            theme="vs-dark"
                            value={DEFAULT_RESPONSE_PAYLOAD}
                          />
                        </Option>
                      </Grid>
                    </Grid>
                    {/* <Option
                      endAdornment={
                        <Button onClick={openResDialog} variant="outlined">
                          Generate
                        </Button>
                      }
                      id="step_chatResponseSchema"
                      optionTitle="Chat response schema"
                    >
                      <FormJsonEditor<FormValues>
                        editorProps={{ height: '20rem' }}
                        fieldPath="chatResponseSchema"
                      />
                    </Option> */}

                    <FormTextField
                      fieldConfig={{
                        name: 'githubUrl',
                        label: 'Github URL (Optional)',
                        placeholder: 'https://github.com/alt-research',
                      }}
                      id="step_githubUrl"
                    />
                    <EnvVarsFieldArray />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <PublishAgentSummaryPanel
                      isSubmitting={isPendingPublish}
                      sx={{
                        mt: {
                          xs: 10,
                        },
                        m: {
                          xs: 3,
                          md: 5,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <DevTool control={form.control} />
            </form>
          </FormProvider>
        </Box>
      </AaasLayout>
    </>
  );
};
