import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogProps, Stack, SxProps, Typography } from '@mui/material';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { Character } from 'pages/aaas/components/ConfigSimpleMode/types';
import { useEnvVarsStore } from 'pages/aaas/NewAgentDeployment/store/useEnvVarsStore';
import { ExternalLink } from 'pages/raas/components';
import { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { DialogContainer, DialogHeader } from 'shared/components/Dialog';
import {
  FormNumericTextField,
  FormPasswordTextField,
  FormSelectField,
  FormTextField,
  IFormTextField,
} from 'shared/components/form';
import IconInfo from 'shared/components/icons/IconInfo';
import { useBreakpoints } from 'shared/hooks/ui/useBreakpoints';
import { AAATemplate } from 'shared/types/protoc-gen/bffaaa';
import * as yup from 'yup';

import {
  ENV_VARS_FORM_MULTILINE_FIELDS,
  ENV_VARS_FORM_NON_SENSITIVE_FIELDS,
  ENV_VARS_FORM_NUMERIC_FIELDS,
  ENV_VARS_FORM_SELECT_FIELDS,
  ENV_VARS_HELP_TEXT,
  ENV_VARS_OPTIONS,
  ENV_VARS_PLACEHOLDER_TEXT,
} from '../constants';
import { envVarLabelMap } from '../labels';
import { EnvVarsFormValues } from '../types';
import { getEnvVarValidationSchema } from '../validation';

const bodyProps: SxProps = { maxHeight: '60vh', overflow: 'auto' };

interface IEnvVarsDialog extends DialogProps {
  template?: AAATemplate;
  envList?: string[];
  currentCharacterMetadata?: Partial<Character>;
  onConfirmed: (envVars: EnvVarsFormValues) => void;
}

export const EnvVarsDialog: React.FC<IEnvVarsDialog> = ({
  currentCharacterMetadata,
  envList,
  onClose,
  onConfirmed,
  open,
  template,
  ...props
}) => {
  const { addAlert } = useAlerts();
  const { envVars, setEnvVars } = useEnvVarsStore();
  const { sm } = useBreakpoints();

  const form = useForm<EnvVarsFormValues>({
    mode: 'onChange',
    resolver: yupResolver(
      getEnvVarValidationSchema(envList ?? []) as yup.ObjectSchema<EnvVarsFormValues>,
    ),
  });

  const handleClose = useCallback(() => onClose?.({}, 'backdropClick'), [onClose]);

  const handleConfirmed = useCallback(
    (formValues: EnvVarsFormValues) => {
      onConfirmed(formValues);
      form.reset();
      addAlert({
        severity: ALERT_SEVERITY.INFO,
        title: 'Env variables set',
        desc: 'Environment variables are set. Ready for deployment!',
      });
      handleClose();
    },
    [addAlert, form, handleClose, onConfirmed],
  );

  const onSubmit: SubmitHandler<EnvVarsFormValues> = useCallback(
    formValues => {
      handleConfirmed(formValues);
      setEnvVars(formValues);
    },
    [handleConfirmed, setEnvVars],
  );

  const onSubmitError: SubmitErrorHandler<EnvVarsFormValues> = useCallback(
    err => {
      console.error(err);
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Failed to set env variables',
        desc: 'All fields are required. Please fill them out.',
      });
    },
    [addAlert],
  );

  const renderEnvVarAlert = useCallback((envVar: string, enabled?: boolean) => {
    switch (envVar) {
      case 'NETWORK_ID':
        return (
          enabled && (
            <Alert>
              <Stack>
                <Typography>
                  For the Network ID configuration, two options are available: base-sepolia
                  (recommended) or base-mainnet. Please be advised that base-mainnet integration is
                  currently in alpha phase and should be implemented with appropriate precautions.
                  Additionally, ensure you maintain secure backups of both your CDP API key and
                  private key credentials.
                </Typography>
              </Stack>
            </Alert>
          )
        );
      // Add more env vars for special cases here
      default:
        return undefined;
    }
  }, []);

  const envVarAlertEnabled = useMemo(
    () => ({
      NETWORK_ID: template?.name?.toLowerCase()?.includes('agentkit'),
      // Add more env vars for special cases here
    }),
    [template?.name],
  );

  useEffect(() => {
    form.reset();

    for (const [key, value] of Object.entries(envVars)) {
      form.setValue(key, value);
    }
  }, [template, form, envVars]);

  return (
    <Dialog
      data-testid="env-vars-dialog"
      fullScreen={sm}
      maxWidth={'md'}
      onClose={onClose}
      open={open}
      {...props}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit, onSubmitError)}>
          <DialogContainer sx={{ minWidth: { md: '750px', sm: undefined } }}>
            <DialogHeader onClose={handleClose} title="Setup environmental variables" />
            <Stack spacing={3} sx={bodyProps}>
              {['eliza', 'elizabnb'].includes(template?.id?.toLowerCase() || '') &&
                currentCharacterMetadata?.clients?.includes('twitter') && (
                  <Alert>
                    <Stack>
                      <Typography>
                        {`This agent requires login access to an X/Twitter account with two-factor authentication (2FA) disabled. For security purposes, it's recommended to create a new X/Twitter account specifically to use this agent.`}
                      </Typography>
                      <ExternalLink
                        href="https://docs.altlayer.io/altlayer-documentation/autonome/troubleshooting-guide"
                        sx={{ fontSize: 12, mt: 1 }}
                      >
                        Having trouble with your agent logging into your X/Twitter account? Check
                        out our troubleshooting guide here
                      </ExternalLink>
                    </Stack>
                  </Alert>
                )}

              {envList?.map((envVar, index) => (
                <EnvVarTextField
                  envVar={envVar}
                  key={[envVar, index].join('-')}
                  renderAlert={() =>
                    renderEnvVarAlert(
                      envVar,
                      envVarAlertEnabled?.[envVar as keyof typeof envVarAlertEnabled],
                    )
                  }
                />
              ))}
            </Stack>
            <Stack direction="row" spacing="24px">
              <LoadingButton
                data-testid="env-confirm-button"
                fullWidth
                loading={form.formState.isSubmitting}
                type="submit"
                variant="contained"
              >
                <Typography>Confirm</Typography>
              </LoadingButton>
            </Stack>
          </DialogContainer>
        </form>
      </FormProvider>
    </Dialog>
  );
};

type ITextFieldComponent = (props: IFormTextField) => ReactNode;

const EnvVarTextField: FC<{
  envVar: string;
  renderAlert?: () => ReactNode;
}> = ({ envVar, renderAlert }) => {
  const form = useFormContext<EnvVarsFormValues>();

  const isNonSensitive = useMemo(
    () => ENV_VARS_FORM_NON_SENSITIVE_FIELDS.includes(envVar),
    [envVar],
  );
  const isMultiline = useMemo(() => ENV_VARS_FORM_MULTILINE_FIELDS.includes(envVar), [envVar]);
  const isNumeric = useMemo(() => ENV_VARS_FORM_NUMERIC_FIELDS.includes(envVar), [envVar]);
  const isError = useMemo(() => !!form.formState.errors?.[envVar], [envVar, form.formState.errors]);
  const isSelect = useMemo(() => ENV_VARS_FORM_SELECT_FIELDS.includes(envVar), [envVar]);

  const TextFieldComponent = useMemo<ITextFieldComponent>(() => {
    if (isNumeric) {
      return FormNumericTextField;
    } else if (isNonSensitive) {
      return FormTextField;
    } else {
      return FormPasswordTextField;
    }
  }, [isNonSensitive, isNumeric]);

  return (
    <Stack
      spacing={2}
      sx={{
        '.MuiSelect-select': {
          border: 0,
          p: 3,
        },
        '.MuiFormLabel-root': {
          top: '9px',
          left: '9px',
        },
      }}
    >
      {envVarLabelMap[envVar] ? (
        typeof envVarLabelMap[envVar] === 'string' ? (
          <Typography variant="captionC">{envVarLabelMap[envVar]}</Typography>
        ) : (
          envVarLabelMap[envVar]
        )
      ) : (
        <Typography variant="captionC">{envVar}</Typography>
      )}
      {renderAlert?.()}
      {isSelect ? (
        <FormSelectField
          data-testid={`input-field-${envVar}`}
          displayEmpty
          error={isError}
          fieldConfig={{
            name: envVar,
            placeholder: envVar.replaceAll('_', ' ').replace('TWITTER', 'X'),
          }}
          fullWidth
          helperText={
            isError
              ? form.formState.errors?.[envVar]?.message
              : ENV_VARS_HELP_TEXT?.[envVar] ?? undefined
          }
          items={ENV_VARS_OPTIONS?.[envVar] ?? []}
          renderValue={(value: unknown) => {
            if (!value) {
              return (
                <Typography sx={{ color: theme => theme.colors.functional.text.lint }}>
                  {ENV_VARS_PLACEHOLDER_TEXT?.[envVar] ||
                    envVar.replaceAll('_', ' ').replace('TWITTER', 'X')}
                </Typography>
              );
            }

            return <>{ENV_VARS_OPTIONS?.[envVar]?.find(item => item.value === value)?.label}</>;
          }}
        />
      ) : (
        <TextFieldComponent
          data-testid={`input-field-${envVar}`}
          {...(form.formState.errors?.[envVar]?.message?.includes('top up') && {
            FormHelperTextProps: { sx: { '&&': { color: '#FFA756' } } },
          })}
          error={isError}
          fieldConfig={{
            name: envVar,
            placeholder: envVar.replaceAll('_', ' ').replace('TWITTER', 'X'),
          }}
          fullWidth
          helperText={
            isError
              ? form.formState.errors?.[envVar]?.message
              : ENV_VARS_HELP_TEXT?.[envVar] ?? undefined
          }
          hideLabel
          minRows={isMultiline ? 4 : undefined}
          multiline={isMultiline}
        />
      )}
    </Stack>
  );
};

const Alert: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        background: theme => theme.colors.gradients.cream,
        border: theme => `1px solid ${theme.colors.functional.subject.border}`,
        p: 3,
      }}
    >
      <IconInfo />

      {children}
    </Stack>
  );
};
