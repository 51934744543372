import { Stack, useTheme } from '@mui/material';
import { ADMIN_PATHS } from 'pages/admin/routes';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { FixedSideNavigationBar } from 'shared/components/Layout/FixedSideNavigationBar';
import { PageBanner, PageBannerVariant } from 'shared/components/Layout/PageBanner';
import { NavigationItem } from 'shared/types/navigation';

export const AdminLayout = () => {
  const theme = useTheme();

  const sideNavigationItems: NavigationItem[] = useMemo(
    () => [
      {
        id: 'admin',
        label: 'Overview',
        items: [
          {
            label: 'Manage Promo Codes',
            to: ADMIN_PATHS.MANAGE_PROMO_CODES,
          },
          {
            label: 'Manage Agent Requests',
            to: ADMIN_PATHS.MANAGE_AGENT_REQUESTS,
          },
          {
            label: 'Manage Finetune Sources',
            to: ADMIN_PATHS.MANAGE_FINETUNE_SOURCES,
          },
          {
            label: 'Manage RaaS Bills',
            to: ADMIN_PATHS.MANAGE_CLIENTS,
          },
        ],
      },
    ],
    [],
  );

  return (
    <Stack>
      <PageBanner title={'Admin Dashboard'} variant={PageBannerVariant.ADMIN} />

      <Stack flexDirection="row" sx={{ position: 'relative', mb: '164px', background: '#fafafa' }}>
        <FixedSideNavigationBar
          primaryColor={theme.colors.schema.altLayerPrimary}
          sideNavigationItems={sideNavigationItems}
          sx={{
            position: 'sticky',
            top: '0',
            alignSelf: 'flex-start',
            zIndex: 100,
            overflow: 'auto',
            maxHeight: '100vh',
          }}
        />

        <Stack
          sx={{
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            overflowY: 'auto',
          }}
        >
          <Outlet />
        </Stack>
      </Stack>
    </Stack>
  );
};
