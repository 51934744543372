import { Stack, StackProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface ILabel extends StackProps {
  label: ReactNode;
  subLabel?: ReactNode;
  labelEndAdornment?: ReactNode;
}

export const Label: FC<ILabel> = ({ children, label, labelEndAdornment, subLabel, ...props }) => (
  <Stack alignItems="start" spacing={1} width="100%" {...props}>
    <Stack width="100%">
      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
        <Typography variant="captionC">{label}</Typography>
        {labelEndAdornment}
      </Stack>

      {subLabel && <Typography variant="caption">{subLabel}</Typography>}
    </Stack>
    {children}
  </Stack>
);
