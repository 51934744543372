import { Button, Grid, IconButton, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { FieldPath, FormProvider, useFormContext } from 'react-hook-form';
import { bff } from 'shared/api';
import {
  FormSelectDateField,
  FormSelectField,
  FormTextFieldBase,
  Label,
} from 'shared/components/form';
import { FormGroupContainer, IFormGroupContainer } from 'shared/components/form/form-group';
import { IconCircleMinus } from 'shared/components/icons/IconCircleMinus';
import { IconCopy } from 'shared/components/icons/IconCopy';
import { useCopyToClipboard } from 'shared/hooks/ui';
import { ListRollupReply } from 'shared/types/protoc-gen/raas-rollup';

import { BasicInfoFormValues } from './types';

interface IBasicInfoFormGroup extends Omit<IFormGroupContainer, 'title'> {
  writeMode: boolean;
  includeBindingChains?: boolean;
}

export const BasicInfoFormGroup = ({
  includeBindingChains = false,
  writeMode = true,
  ...props
}: IBasicInfoFormGroup) => {
  const form = useFormContext<BasicInfoFormValues>();
  const formValues = form.watch();

  console.log('formValues:', formValues);

  const [, copy] = useCopyToClipboard();

  const renderCopyIconButton = useCallback(
    (field: FieldPath<BasicInfoFormValues>) => {
      return (
        <IconButton onClick={() => copy(String(form.getValues(field)))}>
          <IconCopy />
        </IconButton>
      );
    },
    [copy, form],
  );

  const { data: getRollupListReply, isPending: isGetRollupListPending } = useQuery({
    queryKey: ['GET_CHAINS'],
    queryFn: async () => {
      const res = await bff.get<ListRollupReply>(`bff/rollups`);

      return res.data;
    },
  });

  return (
    <FormProvider {...form}>
      <FormGroupContainer
        data-testid="basic-info-form-group-container"
        sx={{
          '.MuiInputBase-root.Mui-disabled': {
            background: '#fff',
            borderRadius: 0,
          },
        }}
        title="Basic Information"
        {...props}
      >
        <Label id="clientName_label" label="Client Name">
          <FormTextFieldBase<BasicInfoFormValues>
            InputProps={
              !writeMode
                ? {
                    endAdornment: renderCopyIconButton('clientName'),
                  }
                : undefined
            }
            disabled={!writeMode}
            fieldConfig={{ name: 'clientName' }}
            id="clientName_field"
            placeholder="Enter client name"
          />
        </Label>
        <Label id="clientNameInAgreement_label" label="Client Name in Agreement">
          <FormTextFieldBase<BasicInfoFormValues>
            InputProps={
              !writeMode
                ? {
                    endAdornment: renderCopyIconButton('clientNameInAgreement'),
                  }
                : undefined
            }
            disabled={!writeMode}
            fieldConfig={{ name: 'clientNameInAgreement' }}
            id="clientNameInAgreement_field"
            placeholder="Enter client name in agreement"
          />
        </Label>
        <Label id="contactEmails_label" label="Contact Emails">
          <FormTextFieldBase<BasicInfoFormValues>
            InputProps={
              !writeMode
                ? {
                    endAdornment: renderCopyIconButton('contactEmails'),
                  }
                : undefined
            }
            disabled={!writeMode}
            fieldConfig={{
              name: 'contactEmails',
              placeholder: 'Enter emails separated by commas',
            }}
            id="contactEmails_field"
          />
        </Label>

        {includeBindingChains && (
          <Label
            label="Binding chains"
            labelEndAdornment={
              <Button
                disabled={!writeMode}
                id="addBindingChainButton"
                onClick={() => {
                  form.setValue('bindingChains', [
                    ...(formValues.bindingChains ?? []),
                    { chainId: undefined, launchingDate: undefined, shutdownDate: undefined },
                  ]);
                }}
                size="small"
              >
                Add
              </Button>
            }
          >
            <Stack spacing={3} width="100%">
              {formValues.bindingChains?.map((_, index) => {
                return (
                  <Stack
                    alignItems="center"
                    data-testid="binding-chain"
                    direction="row"
                    key={`binding-chain-${index}`}
                    spacing={1}
                    width="100%"
                  >
                    <Grid alignItems="center" container direction="row" spacing={2} width="100%">
                      <Grid item md={6} xs={12}>
                        <Label data-testid="chainId_label" id="chainId_label" label="Chain ID">
                          <FormSelectField
                            data-testid="chainId_field"
                            disabled={!writeMode}
                            fieldConfig={{ name: `bindingChains.${index}.chainId` }}
                            id="chainId_field"
                            items={
                              getRollupListReply?.rollups?.map(rollup => ({
                                label: `${rollup.name} (Chain ID: ${rollup.id})`,
                                value: rollup.id,
                              })) ?? []
                            }
                            loading={isGetRollupListPending}
                            placeholder="Select chain"
                          />
                        </Label>
                      </Grid>

                      <Grid item sm xs={12}>
                        <Label
                          data-testid="launchingDate_label"
                          id="launchingDate_label"
                          label="Launching Date"
                        >
                          <FormSelectDateField
                            data-testid="launchingDate_field"
                            disabled={!writeMode}
                            fieldConfig={{ name: `bindingChains.${index}.launchingDate` }}
                            format="YYYY/MM/DD"
                            id="launchingDate_field"
                            placeholder="Select date"
                          />
                        </Label>
                      </Grid>

                      <Grid item sm xs={12}>
                        <Label
                          data-testid="shutdownDate_label"
                          id="shutdownDate_label"
                          label="Shutdown Date"
                        >
                          <FormSelectDateField
                            data-testid="shutdownDate_field"
                            disabled={!writeMode}
                            fieldConfig={{ name: `bindingChains.${index}.shutdownDate` }}
                            format="YYYY/MM/DD"
                            id="shutdownDate_field"
                            placeholder="Select date"
                          />
                        </Label>
                      </Grid>

                      <Grid item xs="auto">
                        <IconButton
                          disabled={!writeMode}
                          id={`removeBindingChainButton_${index}`}
                          onClick={() => {
                            form.setValue(
                              'bindingChains',
                              formValues.bindingChains?.filter((_, i) => i !== index) ?? [],
                            );
                          }}
                          sx={{ pt: 3 }}
                        >
                          <IconCircleMinus />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Stack>
                );
              })}
            </Stack>
          </Label>
        )}
      </FormGroupContainer>
    </FormProvider>
  );
};
