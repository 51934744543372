import { IconButton } from '@mui/material';
import { useCallback } from 'react';
import { FieldPath, FormProvider, useFormContext } from 'react-hook-form';
import {
  FormNumericTextField,
  FormSelectField,
  FormSelectItem,
  FormTextFieldBase,
  Label,
} from 'shared/components/form';
import { FormGroupContainer, IFormGroupContainer } from 'shared/components/form/form-group';
import { IconCopy } from 'shared/components/icons/IconCopy';
import { useCopyToClipboard } from 'shared/hooks/ui';

import { AgreementInfoFormValues } from './types';

interface IAgreementInfoFormGroup extends Omit<IFormGroupContainer, 'title'> {
  writeMode: boolean;
}

export const AgreementInfoFormGroup = ({ writeMode = true, ...props }: IAgreementInfoFormGroup) => {
  const form = useFormContext<AgreementInfoFormValues>();
  const [, copy] = useCopyToClipboard();

  const renderCopyIconButton = useCallback(
    (field: FieldPath<AgreementInfoFormValues>) => {
      return (
        <IconButton onClick={() => copy(String(form.getValues(field)))}>
          <IconCopy />
        </IconButton>
      );
    },
    [copy, form],
  );

  return (
    <FormProvider {...form}>
      <FormGroupContainer
        data-testid="agreement-info-form-group-container"
        sx={{
          '.MuiInputBase-root.Mui-disabled': {
            background: '#fff',
            borderRadius: 0,
          },
        }}
        title="Agreement Information"
        {...props}
      >
        <Label id="agreementStatus_label" label="Agreement status">
          <FormSelectField<FormSelectItem>
            disabled={!writeMode}
            fieldConfig={{ name: 'agreementStatus' }}
            id="agreementStatus_field"
            items={[
              { label: 'Active', value: 'Active' },
              { label: 'Pause', value: 'Pause' },
              { label: 'Terminated', value: 'Terminated' },
            ]}
          />
        </Label>
        <Label id="agreementScope_label" label="Agreement scope">
          <FormSelectField<FormSelectItem>
            disabled={!writeMode}
            fieldConfig={{ name: 'agreementScope' }}
            id="agreementScope_field"
            items={[
              { label: 'Testnet', value: 1 },
              { label: 'Mainnet', value: 2 },
              { label: 'Testnet & Mainnet', value: 3 },
            ]}
          />
        </Label>
        <Label id="paymentFrequency_label" label="Payment frequency">
          <FormSelectField<FormSelectItem>
            disabled={!writeMode}
            fieldConfig={{ name: 'paymentFrequency' }}
            id="paymentFrequency_field"
            items={[
              { label: 'Monthly', value: 'Monthly' },
              { label: 'Quarterly', value: 'Quarterly' },
              { label: 'Yearly', value: 'Yearly' },
            ]}
          />
        </Label>
        <Label id="amount_label" label="Amount per payment">
          <FormNumericTextField<AgreementInfoFormValues>
            InputProps={
              !writeMode
                ? {
                    endAdornment: renderCopyIconButton('amount'),
                  }
                : undefined
            }
            disabled={!writeMode}
            fieldConfig={{ name: 'amount' }}
            hideLabel
            id="amount_field"
            placeholder="Enter amount per payment"
          />
        </Label>
        <Label id="agreementLink_label" label="Agreement link">
          <FormTextFieldBase<AgreementInfoFormValues>
            InputProps={
              !writeMode
                ? {
                    endAdornment: renderCopyIconButton('agreementLink'),
                  }
                : undefined
            }
            disabled={!writeMode}
            fieldConfig={{ name: 'agreementLink' }}
            id="agreementLink_field"
            placeholder="Enter agreement link"
          />
        </Label>
        <Label id="notes_label" label="Notes">
          <FormTextFieldBase<AgreementInfoFormValues>
            InputProps={
              !writeMode
                ? {
                    endAdornment: renderCopyIconButton('notes'),
                  }
                : undefined
            }
            disabled={!writeMode}
            fieldConfig={{ name: 'notes' }}
            id="notes_field"
            minRows={4}
            multiline
            placeholder="Enter notes"
          />
        </Label>
      </FormGroupContainer>
    </FormProvider>
  );
};
