import {
  Button,
  FormControl,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { PaymentStatusChip } from 'pages/admin/ManageClients/PaymentStatusChip';
import { ADMIN_PATHS } from 'pages/admin/routes';
import { debounce } from 'perfect-debounce';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { bff } from 'shared/api';
import { Section } from 'shared/components';
import { FormSelectField } from 'shared/components/form';
import { IconRefresh } from 'shared/components/icons/IconRefresh';
import { TableRowSkeleton } from 'shared/components/Skeleton/TableRowSkeleton';
import { Pagination } from 'shared/components/Table/Pagination';
import { useTablePagination } from 'shared/hooks/ui';
import {
  AgreementListReply,
  AgreementListRequest,
  SimpleAgreementInfo,
} from 'shared/types/protoc-gen/bffbillsystem';
import { QUERY_KEYS } from 'shared/types/react-query';

const DEBOUNCE_TIME = 500;

type Status = 'Active' | 'Pause' | 'Terminated' | '';
type FeeStatus = 'Awaiting' | 'Paid' | 'Overdue' | 'PaidAfterOverdue' | '';

interface FormValues {
  clientName: string;
  status: Status;
  serviceFeeStatus: FeeStatus;
  withdrawFeeStatus: FeeStatus;
}

export const ManageClients = () => {
  const navigate = useNavigate();

  const form = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      clientName: '',
      status: '',
      serviceFeeStatus: '',
      withdrawFeeStatus: '',
    },
  });
  const { clientName, serviceFeeStatus, status, withdrawFeeStatus } = form.watch();

  const debouncedClientName = useMemo(
    () =>
      debounce((value: string) => {
        form.setValue('clientName', value);
      }, DEBOUNCE_TIME),
    [form],
  );
  const debouncedStatus = useMemo(
    () => debounce((value: Status) => form.setValue('status', value), DEBOUNCE_TIME),
    [form],
  );
  const debouncedServiceFeeStatus = useMemo(
    () => debounce((value: FeeStatus) => form.setValue('serviceFeeStatus', value), DEBOUNCE_TIME),
    [form],
  );
  const debouncedWithdrawFeeStatus = useMemo(
    () => debounce((value: FeeStatus) => form.setValue('withdrawFeeStatus', value), DEBOUNCE_TIME),
    [form],
  );

  const { data: agreements, isPending: getAgreementsPending } = useQuery({
    queryKey: [
      QUERY_KEYS.ADMIN_CLIENT_LIST,
      { clientName, status, serviceFeeStatus, withdrawFeeStatus },
    ],
    queryFn: async (): Promise<SimpleAgreementInfo[]> => {
      const res = await bff.get<AgreementListReply>('/bff/bill/agreements', {
        params: {
          clientName,
          status,
          serviceFeeStatus,
          withdrawFeeStatus,
        } satisfies Partial<AgreementListRequest>,
      });

      return res.data.agreementInfo;
    },
  });
  const { filteredData, paginationCount, setCurrentPage } = useTablePagination({
    data: agreements ?? [],
  });

  return (
    <Section
      renderSubHeaderEndContent={
        <Stack>
          <Button
            id="addClientButton"
            onClick={() => navigate(ADMIN_PATHS.MANAGE_CLIENTS_CREATE)}
            size="small"
          >
            Add
          </Button>
        </Stack>
      }
      title="Manage Clients"
    >
      <TableContainer>
        <FormProvider {...form}>
          <Stack
            component="form"
            direction="row"
            onSubmit={form.handleSubmit(() => form.reset())}
            sx={{
              '.MuiSelect-select': {
                border: 0,
                p: 3,
              },
              '.MuiFormLabel-root': {
                top: '9px',
              },
            }}
          >
            <FormControl data-testid="clientName_formControl" fullWidth>
              <TextField
                fullWidth
                onChange={event => debouncedClientName(event.target.value ?? '')}
                placeholder="Search by client name"
              />
            </FormControl>
            <FormSelectField
              fieldConfig={{ name: 'status' }}
              inputLabel="Status"
              items={[
                { label: 'Active', value: 'Active' },
                { label: 'Pause', value: 'Pause' },
                { label: 'Terminated', value: 'Terminated' },
              ]}
              onChange={event => debouncedStatus(event.target.value as Status)}
            />
            <FormSelectField
              fieldConfig={{ name: 'serviceFeeStatus' }}
              inputLabel="Service fee status"
              items={[
                { label: 'Awaiting', value: 'Awaiting' },
                { label: 'Paid', value: 'Paid' },
                { label: 'Overdue', value: 'Overdue' },
                { label: 'Paid after overdue', value: 'PaidAfterOverdue' },
              ]}
              onChange={event => debouncedServiceFeeStatus(event.target.value as FeeStatus)}
            />
            <FormSelectField
              fieldConfig={{ name: 'withdrawFeeStatus' }}
              inputLabel="Withdraw fee status"
              items={[
                { label: 'Awaiting', value: 'Awaiting' },
                { label: 'Paid', value: 'Paid' },
                { label: 'Overdue', value: 'Overdue' },
                { label: 'Paid after overdue', value: 'PaidAfterOverdue' },
              ]}
              onChange={event => debouncedWithdrawFeeStatus(event.target.value as FeeStatus)}
            />
            <Button type="submit" variant="sheet">
              <Stack alignItems="center" direction="row" spacing={2}>
                <IconRefresh />
                <Typography fontSize={18} variant="bodySmallM">
                  Reset
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </FormProvider>
        <Table>
          <TableHead>
            <TableRow>
              {[
                'Client',
                'Payment period',
                'Agreement status',
                'Service fee status',
                'Withdraw fee status',
                'Notes',
                'Details',
              ].map(header => (
                <TableCell key={header}>
                  <Typography variant="captionC">{header}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getAgreementsPending
              ? Array.from({ length: 10 }).map((_, index) => (
                  <TableRowSkeleton cellCount={7} key={index} />
                ))
              : filteredData.map(agreement => (
                  <TableRow
                    data-testid={`tableRow_${agreement?.clientName}`}
                    key={agreement?.agreementId}
                  >
                    <TableCell id={`tableCell_clientName_${agreement?.clientName}`}>
                      <Typography variant="bodySmallM">{agreement?.clientName}</Typography>
                    </TableCell>
                    <TableCell id={`tableCell_paymentFrequency_${agreement?.paymentFrequency}`}>
                      <Typography variant="bodySmall">{agreement?.paymentFrequency}</Typography>
                    </TableCell>
                    <TableCell id={`tableCell_agreementStatus_${agreement?.agreementStatus}`}>
                      {agreement?.agreementStatus && (
                        <PaymentStatusChip status={agreement?.agreementStatus} />
                      )}
                    </TableCell>
                    <TableCell id={`tableCell_serviceFeeStatus_${agreement?.serviceFeeStatus}`}>
                      <Stack alignItems="center" direction="row" spacing={1}>
                        {agreement?.serviceFeeNextPayment && (
                          <Typography variant="bodySmall">
                            {dayjs(agreement?.serviceFeeNextPayment).format('MM/DD/YYYY')}
                          </Typography>
                        )}
                        {agreement?.serviceFeeStatus && (
                          <PaymentStatusChip status={agreement?.serviceFeeStatus} />
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell id={`tableCell_withdrawFeeStatus_${agreement?.withdrawFeeStatus}`}>
                      <Stack alignItems="center" direction="row" spacing={1}>
                        {agreement?.withdrawFeeNextPayment && (
                          <Typography variant="bodySmall">
                            {dayjs(agreement?.withdrawFeeNextPayment).format('MM/DD/YYYY')}
                          </Typography>
                        )}
                        {agreement?.withdrawFeeStatus && (
                          <PaymentStatusChip status={agreement?.withdrawFeeStatus} />
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell id={`tableCell_notes_${agreement?.notes}`}>
                      <Typography variant="caption">{agreement?.notes}</Typography>
                    </TableCell>
                    <TableCell id={`tableCell_action`}>
                      <Button
                        id={`viewClientButton_clientName_${agreement?.clientName}`}
                        onClick={() =>
                          navigate(
                            ADMIN_PATHS.MANAGE_CLIENTS_DETAILS.replace(
                              ':clientId',
                              agreement?.agreementId?.toString(),
                            ),
                          )
                        }
                        size="small"
                        variant="contained"
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
          <Pagination
            paginationOptions={{
              count: paginationCount,
              onChange: (_, page) => setCurrentPage(page),
            }}
            sx={{ pl: 5, py: 3 }}
          />
        </Table>
      </TableContainer>
    </Section>
  );
};
