import { toRegex } from 'shared/utils/strings';
import { isAddress } from 'viem';
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: () => 'This field is required.',
    notType: ({ type }) => {
      return type === 'number' ? 'Please enter a numeric value.' : `Please enter a ${type}.`;
    },
  },
  string: {
    min: ({ min }) => `Please enter at least ${min} characters.`,
    max: ({ max }) => `Please enter at most ${max} characters.`,
    email: () => 'Please enter a valid email address.',
    url: () => 'Please enter a valid url.',
  },
  number: {
    min: ({ min }) => `Please enter a number greater than or equal to ${min}.`,
    max: ({ max }) => `Please enter a number smaller than or equal to ${max}.`,
    integer: () => 'Please enter an integer value.',
  },
});

yup.addMethod(yup.string, 'address', function address() {
  return this.test('is-address', function (value?: string) {
    if (value === undefined) {
      return true;
    }

    if (!isAddress(value || '', { strict: false })) {
      return this.createError({ message: 'Please enter a valid address.' });
    }

    if (!isAddress(value || '', { strict: true })) {
      return this.createError({ message: 'Must use checksummed address.' });
    }

    return true;
  });
});

yup.addMethod(yup.number, 'step', function (stepValue, message) {
  return this.test(
    'step',
    message || `Only multiples of ${stepValue} are allowed`,
    function (value: string) {
      return Number(value) % stepValue === 0;
    },
  );
});

yup.addMethod(yup.string, 'noLeadingDigit', function noLeadingDigit() {
  return this.matches(/^[^\d]/gi, 'Cannot begin with a digit');
});

yup.addMethod(yup.string, 'noUrl', function noUrl(message?: string) {
  return this.matches(/^((?!(www|http)).)*$/gi, message || 'Cannot contain urls');
});

yup.addMethod(
  yup.string,
  'isRegex',
  function isRegex(message = 'Please enter a valid javascript regular expression e.g. /abc/') {
    return this.test('is-regex', message, function (value) {
      if (!value) return true; // empty value is valid, should be handled by required()

      if (toRegex(value)) return true;

      return this.createError();
    });
  },
);

yup.addMethod(
  yup.string,
  'isDockerImg',
  function isDockerImg(message = 'Please enter a valid docker image', requireTag = true) {
    return this.test('is-docker-img', message, function (value) {
      if (!value) return true; // empty value is valid, should be handled by required()

      const regexWRequiredTag =
        /^(?:[a-zA-Z0-9.-]+(?::\d+)?\/)?(?:[a-zA-Z0-9_.-]+\/)*[a-zA-Z0-9_.-]+:[a-zA-Z0-9_.-]{1,128}$/;
      const regexWOptionalTag =
        /^(?:[a-zA-Z0-9.-]+(?::\d+)?\/)?(?:[a-zA-Z0-9_.-]+\/)*[a-zA-Z0-9_.-]+(?:[:][a-zA-Z0-9_.-]{1,128})?$/;

      if (requireTag) {
        if (regexWRequiredTag.test(value)) {
          return true;
        } else {
          return regexWOptionalTag.test(value)
            ? this.createError({ message: 'Tag must be explicitly defined' })
            : this.createError();
        }
      }

      if (regexWOptionalTag.test(value)) return true;

      return this.createError();
    });
  },
);

yup.addMethod(yup.array, 'unique', function (message, mapper = (a: any) => a) {
  return this.test('unique', message, function (list) {
    return list?.length === new Set(list?.map(mapper)).size;
  });
});

yup.addMethod(
  yup.array,
  'containsAllValuesForProperty',
  function (
    requiredValues,
    accessor: string | ((item: any) => any) = (item: any) => item,
    messageBuilder?: (missingItems: any[]) => string,
  ) {
    return this.test('contains-all-values-for-property', function (items) {
      if (!items || !Array.isArray(items)) {
        return false;
      }

      const existingValues = items.map(
        typeof accessor === 'string' ? item => item[accessor] : accessor,
      );

      const missingValues = requiredValues.filter((value: any) => !existingValues.includes(value));

      // If no missing values, the test passes
      if (missingValues.length === 0) {
        return true;
      }

      const errorMessage =
        messageBuilder?.(missingValues) ||
        `Value(s) are missing but required: ${missingValues
          ?.map((cur: any) => `'${cur}'`)
          ?.join(', ')}`;

      return this.createError({ message: errorMessage });
    });
  },
);

yup.addMethod(yup.string, 'validDate', function (message = 'Invalid date format') {
  return this.test('valid-date', message, value => {
    if (!value) return true;

    return value ? !isNaN(Date.parse(value)) : false;
  });
});

yup.addMethod(
  yup.string,
  'privateKey',
  function (
    message = 'Invalid private key format. Must be a 64-character hex string starting with 0x',
  ) {
    return this.test('valid-web3-private-key', message, value => {
      if (!value) return true;

      return value ? /^0x[a-fA-F0-9]{64}$/.test(value) : false;
    });
  },
);
