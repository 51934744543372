import { FaceRetouchingNatural, RestartAlt, Troubleshoot } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { AgentHistoryLogSection } from 'pages/aaas/AaasDetails/components/AgentHistoryLogSection';
import { ButtonGroupContainer } from 'pages/aaas/AaasDetails/components/ButtonGroupContainer';
import { useApp } from 'pages/aaas/AaasDetails/hooks';
import { useAgentId } from 'pages/aaas/AaasDetails/hooks/useAgentId';
import { useAgentMetadata } from 'pages/aaas/AaasDetails/hooks/useAgentMetadata';
import { AAAS_PATHS } from 'pages/aaas/routes';
import { ExternalLink } from 'pages/raas/components';
import { showExperimentalFeatures } from 'pages/raas/DeploymentDetails/helpers/visibility';
import { ReactNode, useMemo } from 'react';
import Countdown, { CountdownRendererFn } from 'react-countdown';
import { FaTwitter } from 'react-icons/fa';
import { MdSmartToy } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import { IconDelete } from 'shared/components/icons/IconDelete';
import { IconEdit } from 'shared/components/icons/IconEdit';
import { IconExternalLink } from 'shared/components/icons/IconExternalLink';
import { Section } from 'shared/components/Section';
import { StatusLabel } from 'shared/components/StatusLabel';
import { WithInfo } from 'shared/components/WithInfo';
import { useDisclosure } from 'shared/hooks/ui';
import { useBreakpoints } from 'shared/hooks/ui/useBreakpoints';
import { useMenuDisclosure } from 'shared/hooks/ui/useMenuDisclosure';
import { AAATemplateHostType, aAATemplateHostTypeToJSON } from 'shared/types/protoc-gen/bffaaa';

import { ApiLoginDetailsDialog } from './ApiLoginDetailsDialog';
import { DeleteAppDialog } from './DeleteAppDialog';
import { EditAgentProfileDialog } from './EditAgentProfileDialog';
import { FinetuneDialog } from './FinetuneDialog';
import { PromptTweetDialog } from './PromptTweetDialog';
import { RestartAppDialog } from './RestartAppDialog';
import { useFineTune } from './useFinetune';
import { useStatus } from './useStatus';
import { useTweet } from './useTweet';

enum ActionButtonType {
  Button = 'button',
  Menu = 'menu',
}
interface IActionButtons {
  id: string;
  label: ReactNode;
  onClick?: (() => void) | ((event?: React.MouseEvent<HTMLElement>) => void);
  icon?: ReactNode;
  disabled?: boolean;
  show?: boolean;
  type: ActionButtonType;
  menuAnchorEl?: HTMLElement | null;
  menuItems?: Array<ITroubleshootMenuItem>;
  onCloseMenu?: () => void;
  isOpenMenu?: boolean;
}

interface ITroubleshootMenuItem {
  id: string;
  name: string;
  onClick: () => void;
  icon: ReactNode;
  show: boolean;
}

export const DetailsView = () => {
  const navigate = useNavigate();
  const { sm } = useBreakpoints();

  const { data: app, isPending } = useApp();
  const { data: agentId } = useAgentId();
  const { deployed, failureCount, isPending: healthzPending } = useStatus(app);
  const { data: currentMetadata, isPending: isCurrentMetadataPending } = useAgentMetadata(
    app,
    agentId,
  );

  const {
    onClose: onCloseFinetuneDialog,
    onOpen: onOpenFinetuneDialog,
    open: isOpenFinetuneDialog,
  } = useDisclosure();
  const {
    onClose: onCloseDeleteDialog,
    onOpen: onOpenDeleteDialog,
    open: isOpenDeleteDialog,
  } = useDisclosure();
  const {
    onClose: onCloseEditAgentProfileDialog,
    onOpen: onOpenEditAgentProfileDialog,
    open: isOpenEditAgentProfileDialog,
  } = useDisclosure();
  const {
    onClose: onClosePromptTweetDialog,
    onOpen: onOpenPromptTweetDialog,
    open: isOpenPromptTweetDialog,
  } = useDisclosure();
  const {
    anchorEl: manageButtonMenuAnchorEl,
    onClose: onCloseManageButtonMenu,
    onOpen: onOpenManageButtonMenu,
    open: isOpenManageButtonMenu,
  } = useMenuDisclosure();
  const {
    anchorEl: troubleshootMenuAnchorEl,
    onClose: onCloseTroubleshootMenu,
    onOpen: onOpenTroubleshootMenu,
    open: openedTroubleshootMenu,
  } = useMenuDisclosure();
  const {
    onClose: onCloseRestartDialog,
    onOpen: onOpenRestartDialog,
    open: isOpenRestartDialog,
  } = useDisclosure();
  const {
    onClose: onCloseApiLoginDetailsDialog,
    onOpen: onOpenApiLoginDetailsDialog,
    open: isOpenApiLoginDetailsDialog,
  } = useDisclosure();

  const { isPromptTweeting, isTweeting, tweet } = useTweet();

  const isHostTypeChatbox =
    String(app?.template?.type) ===
    aAATemplateHostTypeToJSON(AAATemplateHostType.AAA_TEMPLATE_HOST_TYPE_CHATBOX);
  const isElizaTemplate = ['eliza', 'elizabnb'].includes(app?.template?.id?.toLowerCase() || '');
  const isTwitterClientEnabled = currentMetadata?.character.clients.includes('twitter');

  const troubleShootMenuItems: Array<ITroubleshootMenuItem> = useMemo(
    () => [
      {
        id: 'edit-agent-credentials-button',
        name: 'Edit Agent Credentials',
        onClick: () => {},
        icon: <FaceRetouchingNatural />,
        show: showExperimentalFeatures,
      },
      {
        id: 'restart-app-button',
        name: 'Restart App',
        onClick: onOpenRestartDialog,
        icon: <RestartAlt />,
        show: true,
      },
    ],
    [onOpenRestartDialog],
  );

  const { isLoadingJobs, jobs } = useFineTune();

  const actionButtons = useMemo<Array<IActionButtons>>(
    () =>
      [
        {
          id: 'chat-button',
          label: (
            <Stack>
              <Typography>Chat with Agent</Typography>
              {(!deployed || healthzPending) && (
                <Typography
                  fontSize={8}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                  }}
                  variant="bodySmall"
                >
                  <CircularProgress size={10} />{' '}
                  {failureCount < 1 ? 'Initializing...' : 'Deploying...'}
                </Typography>
              )}
            </Stack>
          ),
          onClick: () => app && navigate(AAAS_PATHS.AAAS_DETAILS_CHAT.replace(':appId', app.id)),
          icon: <IconExternalLink sx={{ height: 20, width: 20 }} />,
          disabled: !deployed || healthzPending,
          show: isHostTypeChatbox,
          type: ActionButtonType.Button,
        },
        {
          id: 'edit-character-button',
          label: 'Edit Character',
          onClick: () =>
            app && navigate(AAAS_PATHS.AAAS_DETAILS_EDIT_METADATA.replace(':appId', app.id)),
          icon: <IconEdit sx={{ height: 20, width: 20 }} />,
          disabled: !deployed || healthzPending,
          show: isElizaTemplate && !isCurrentMetadataPending,
          type: ActionButtonType.Button,
        },
        {
          id: 'finetune-button',
          label: <WithInfo info="Finetuning is a prerequisite for tweets" text="Finetune" />,
          onClick: onOpenFinetuneDialog,
          icon: <MdSmartToy size={20} />,
          show: isElizaTemplate && isTwitterClientEnabled,
          type: ActionButtonType.Button,
        },
        {
          id: 'tweet-prompt-button',
          label: <WithInfo info="Prompt your agent to tweet about something" text="Prompt Tweet" />,
          onClick: onOpenPromptTweetDialog,
          icon: isPromptTweeting ? <CircularProgress size={20} /> : <FaTwitter size={20} />,
          disabled:
            !deployed ||
            healthzPending ||
            isLoadingJobs ||
            (!jobs?.telegramChannelIds?.length && !jobs?.twittersProfiles?.length),
          show: isElizaTemplate && isTwitterClientEnabled,
          type: ActionButtonType.Button,
        },
        {
          id: 'tweet-button',
          label: (
            <WithInfo
              info="Post random tweet using ambient agent context with a single click"
              text="1 Click Tweet"
            />
          ),
          onClick: tweet,
          icon: isTweeting ? <CircularProgress size={20} /> : <FaTwitter size={20} />,
          disabled:
            !deployed ||
            healthzPending ||
            isLoadingJobs ||
            (!jobs?.telegramChannelIds?.length && !jobs?.twittersProfiles?.length),
          show: isElizaTemplate && isTwitterClientEnabled,
          type: ActionButtonType.Button,
        },
        {
          id: 'troubleshoot-menu',
          label: 'Troubleshoot App',
          onClick: (event?: React.MouseEvent<HTMLElement>) => {
            onOpenTroubleshootMenu(event || ({} as React.MouseEvent<HTMLElement>));
          },
          icon: <Troubleshoot />,
          show: true,
          type: ActionButtonType.Menu,
          menuAnchorEl: troubleshootMenuAnchorEl,
          menuItems: troubleShootMenuItems,
          onCloseMenu: onCloseTroubleshootMenu,
          isOpenMenu: openedTroubleshootMenu,
        },
        {
          id: 'delete-button',
          label: 'Delete App',
          onClick: onOpenDeleteDialog,
          icon: <IconDelete sx={{ height: 20, width: 20 }} />,
          show: true,
          type: ActionButtonType.Button,
        },
      ].filter(Boolean),
    [
      app,
      deployed,
      failureCount,
      healthzPending,
      isCurrentMetadataPending,
      isElizaTemplate,
      isHostTypeChatbox,
      isLoadingJobs,
      isPromptTweeting,
      isTweeting,
      isTwitterClientEnabled,
      jobs?.telegramChannelIds?.length,
      jobs?.twittersProfiles?.length,
      navigate,
      onOpenDeleteDialog,
      onOpenTroubleshootMenu,
      onOpenFinetuneDialog,
      onOpenPromptTweetDialog,
      troubleShootMenuItems,
      troubleshootMenuAnchorEl,
      onCloseTroubleshootMenu,
      openedTroubleshootMenu,
      tweet,
    ],
  );

  const countdownRenderer: CountdownRendererFn = ({ days, hours, minutes, seconds }) => {
    return (
      <Box component="span">
        {days > 0 && `${days} day${days > 1 ? 's' : ''} `}
        {hours > 0 && `${hours} hr${hours > 1 ? 's' : ''} `}
        {minutes > 0 && `${minutes} min${days > 1 ? 's' : ''} `}
        {seconds} sec
      </Box>
    );
  };

  return (
    <>
      <EditAgentProfileDialog
        onClose={onCloseEditAgentProfileDialog}
        onValid={formValues => {
          console.log('formValues', formValues);
        }}
        open={isOpenEditAgentProfileDialog}
      />
      <FinetuneDialog onClose={onCloseFinetuneDialog} open={isOpenFinetuneDialog} />
      <DeleteAppDialog app={app} onClose={onCloseDeleteDialog} open={isOpenDeleteDialog} />
      <RestartAppDialog
        app={app}
        onClose={onCloseRestartDialog}
        onCloseMenu={onCloseTroubleshootMenu}
        open={isOpenRestartDialog}
      />
      <PromptTweetDialog onClose={onClosePromptTweetDialog} open={isOpenPromptTweetDialog} />
      <ApiLoginDetailsDialog
        app={app}
        onClose={onCloseApiLoginDetailsDialog}
        open={isOpenApiLoginDetailsDialog}
      />
      <Stack sx={{ mb: '164px' }}>
        <Section
          renderSubHeaderEndContent={
            isPending ? (
              <Skeleton height="24px" variant="rectangular" width="68px" />
            ) : (
              <Stack direction="row" justifyContent="end" spacing={2}>
                {deployed ? (
                  <StatusLabel
                    data-testid="status-label-live"
                    sx={{ background: theme => theme.colors.schema.leafPrimary }}
                  >
                    Live
                  </StatusLabel>
                ) : (
                  <StatusLabel data-testid="status-label-deploying" sx={{ background: '#F8D97D' }}>
                    Deploying
                  </StatusLabel>
                )}
              </Stack>
            )
          }
          renderSubHeaderStartContent={
            isPending ? (
              <Skeleton variant="text" width="80px" />
            ) : (
              <Typography variant={'bodySmallC'}>{app?.template?.name}</Typography>
            )
          }
        >
          <Stack>
            <Field
              label={'Project name'}
              loading={isPending}
              onClickActionButton={
                showExperimentalFeatures // TODO: remove flag when feature ready for production
                  ? onOpenEditAgentProfileDialog
                  : undefined
              }
            >
              {app?.name}
            </Field>
            {app?.metadata?.twitterAccount && app?.metadata?.twitterAccount !== 'undefined' && (
              <Field label={'Twitter handle'} loading={isPending}>
                @{app?.metadata?.twitterAccount}
              </Field>
            )}
            {app?.endpoints?.apiUrl && (
              <Field label={'App URL'} loading={isPending}>
                <Stack alignItems="center" direction="row" spacing={2}>
                  <Typography>{app?.endpoints?.apiUrl}</Typography>
                  <Typography
                    onClick={onOpenApiLoginDetailsDialog}
                    role="button"
                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                    variant="caption"
                  >
                    View details
                  </Typography>
                </Stack>
              </Field>
            )}
            <Field label={'Server size'} loading={isPending}>
              {'Autoscaling'}
            </Field>
            {app?.createdAt && (
              <Field label={'Created'} loading={isPending}>
                <Typography variant="bodySmallM">
                  <ReactTimeAgo date={new Date(app?.createdAt)} />
                </Typography>
              </Field>
            )}
            {app?.expireAt && (
              <Field label={'Expiration'} loading={isPending}>
                <Typography data-testid="expiration-countdown" variant="bodySmallM">
                  <Countdown date={dayjs(app?.expireAt).toDate()} renderer={countdownRenderer} />
                </Typography>
              </Field>
            )}
            {sm ? (
              <>
                <Button onClick={onOpenManageButtonMenu} sx={{ mx: 3 }}>
                  Manage
                </Button>
                <Menu
                  anchorEl={manageButtonMenuAnchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  onClose={onCloseManageButtonMenu}
                  open={isOpenManageButtonMenu}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {(() => {
                    const filteredActionButtons = actionButtons.filter(
                      actionButton =>
                        actionButton?.show && actionButton.type === ActionButtonType.Button,
                    );

                    const mergedButtons = [
                      ...filteredActionButtons.slice(0, filteredActionButtons.length - 1),
                      ...troubleShootMenuItems.map(item => ({
                        id: item.id,
                        show: item.show,
                        disabled: isPending,
                        onClick: item.onClick,
                        label: item.name,
                        icon: item.icon,
                      })),
                      filteredActionButtons[filteredActionButtons.length - 1],
                    ].filter(Boolean);

                    return mergedButtons
                      .filter(actionButton => actionButton.show)
                      .map(actionButton => (
                        <MenuItem
                          data-testid={`menu-button-${actionButton.label}`}
                          disabled={actionButton?.disabled || isPending}
                          key={actionButton?.id}
                          onClick={() => {
                            actionButton?.onClick?.();
                            onCloseManageButtonMenu();
                          }}
                        >
                          <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="center"
                            spacing="8px"
                          >
                            {actionButton?.icon}
                            <Typography variant="bodySmallC">{actionButton?.label}</Typography>
                          </Stack>
                        </MenuItem>
                      ));
                  })()}
                </Menu>
              </>
            ) : (
              <ButtonGroupContainer>
                {actionButtons
                  .filter(actionButton => actionButton?.show)
                  .map(actionButton => (
                    <>
                      <Button
                        data-testid={`menu-button-${actionButton.label}`}
                        disabled={actionButton.disabled || isPending}
                        key={actionButton.id}
                        onClick={actionButton.onClick}
                      >
                        <Stack alignItems="center" direction="row" spacing="8px">
                          {actionButton.icon}
                          <Typography variant="bodySmallC">{actionButton.label}</Typography>
                        </Stack>
                      </Button>

                      {actionButton.type === ActionButtonType.Menu && (
                        <Menu
                          anchorEl={actionButton.menuAnchorEl}
                          data-testid={`menu-container-${actionButton.label}`}
                          onClose={actionButton.onCloseMenu}
                          open={!!actionButton.isOpenMenu}
                        >
                          {actionButton.menuItems?.map(menuItem => {
                            return (
                              menuItem.show && (
                                <MenuItem
                                  data-testid={`menu-item-${menuItem.name}`}
                                  key={menuItem.name}
                                  onClick={menuItem.onClick}
                                >
                                  <Stack alignItems="center" direction="row" spacing={1}>
                                    {menuItem.icon}
                                    <Typography>{menuItem.name}</Typography>
                                  </Stack>
                                </MenuItem>
                              )
                            );
                          })}
                        </Menu>
                      )}
                    </>
                  ))}
              </ButtonGroupContainer>
            )}
          </Stack>
        </Section>
        {isElizaTemplate && (
          <ExternalLink
            href="https://docs.altlayer.io/altlayer-documentation/autonome/troubleshooting-guide"
            sx={{ fontSize: 12, textAlign: 'center', my: 2 }}
          >
            Having trouble with your agent logging into your X/Twitter account? Check out our
            troubleshooting guide here
          </ExternalLink>
        )}
        <AgentHistoryLogSection />
      </Stack>
    </>
  );
};

const Field: React.FC<{
  loading?: boolean;
  label: ReactNode;
  children: string | ReactNode;
  onClickActionButton?: () => void;
}> = ({ children, label, loading, onClickActionButton }) => {
  const { md, sm } = useBreakpoints();

  return (
    <Stack
      alignItems="center"
      direction={md ? 'row' : 'column'}
      justifyContent="space-between"
      spacing={3}
      sx={{
        borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
        '&:hover': { background: '#fafafa' },
        background: '#fff',
        minHeight: '68px',
        py: 3,
        px: { md: 5, sm: 3, xs: 3 },
      }}
    >
      <Grid container>
        <Grid item md={4} sm={12} xs={12}>
          {loading ? (
            <Box width="357px">
              <Skeleton variant="text" width="55%" />
            </Box>
          ) : (
            <Stack alignItems="start" direction="row" justifyContent="space-between">
              <Typography variant="bodySmallM" width="100%">
                {label}:
              </Typography>

              {sm && onClickActionButton && (
                <Button
                  disabled={loading}
                  onClick={onClickActionButton}
                  size="small"
                  sx={{
                    '&, &:hover': { background: 'transparent' },
                    color: theme => theme.colors.functional.text.primary,
                  }}
                >
                  <Stack alignItems="center" direction="row" justifyContent="center" spacing="8px">
                    <IconEdit sx={{ height: 18, width: 18 }} />

                    <Typography variant="bodySmallC">EDIT</Typography>
                  </Stack>
                </Button>
              )}
            </Stack>
          )}
        </Grid>

        <Grid item md={7} sm={12} xs={12}>
          {loading ? (
            <Skeleton variant="text" width="15%" />
          ) : typeof children === 'string' ? (
            <Typography data-testid={`field-text-${label}`} variant="body1" width="100%">
              {children}
            </Typography>
          ) : (
            children
          )}
        </Grid>

        {md && (
          <Grid item md={1}>
            {onClickActionButton && (
              <Stack
                alignItems={sm ? 'start' : 'end'}
                justifyContent={sm ? 'start' : 'end'}
                mt={{ md: 0, sm: 3, xs: 3 }}
              >
                <Button
                  disabled={loading}
                  fullWidth={sm}
                  onClick={onClickActionButton}
                  size="small"
                  sx={{
                    '&, &:hover': { background: 'transparent' },
                    color: theme => theme.colors.functional.text.primary,
                  }}
                >
                  <Stack alignItems="center" direction="row" justifyContent="center" spacing="8px">
                    <IconEdit sx={{ height: 18, width: 18 }} />

                    <Typography variant="bodySmallC">EDIT</Typography>
                  </Stack>
                </Button>
              </Stack>
            )}
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};
