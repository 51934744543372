import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { ButtonGroupContainer } from 'pages/aaas/AaasDetails/components/ButtonGroupContainer';
import { useApp } from 'pages/aaas/AaasDetails/hooks';
import { useAgentId } from 'pages/aaas/AaasDetails/hooks/useAgentId';
import { useAgentMetadata } from 'pages/aaas/AaasDetails/hooks/useAgentMetadata';
import { getBasicAuthHeaders } from 'pages/aaas/AaasDetails/utils';
import { ConfigAdvancedMode, ConfigSimpleMode } from 'pages/aaas/components';
import { usePollGeneratedCharacter } from 'pages/aaas/hooks';
import { GeneratePersonaDialog } from 'pages/aaas/NewAgentDeployment/components';
import { CharacterFormValues } from 'pages/aaas/NewAgentDeployment/types';
import { characterSchema } from 'pages/aaas/NewAgentDeployment/validation/eliza';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { bff } from 'shared/api';
import { Section } from 'shared/components';
import { IconCirclePlus } from 'shared/components/icons/IconCirclePlus';
import TabbedSection from 'shared/components/TabbedSection';
import { useDisclosure } from 'shared/hooks/ui';
import { UpdateAAAAppReply, UpdateAAAAppRequest } from 'shared/types/protoc-gen/bffaaa';
import { MUTATION_KEYS, QUERY_KEYS } from 'shared/types/react-query';
import { constructEndpointSafe } from 'shared/utils/strings';
import * as yup from 'yup';

export const EditMetadataView = () => {
  const queryClient = useQueryClient();

  const {
    onClose: onCloseGeneratePersonaDialog,
    onOpen: onOpenGeneratePersonaDialog,
    open: isOpenGeneratePersonaDialog,
  } = useDisclosure();

  const { appId } = useParams();
  const { addAlert } = useAlerts();
  const { data: agentId } = useAgentId();
  const { data: app, isPending: isAppPending, isSuccess: isAppSuccess } = useApp({ appId });

  const {
    data: currentMetadata,
    isPending: isCurrentMetadataPending,
    isSuccess: isCurrentMetadataSuccess,
  } = useAgentMetadata(app, agentId);
  const { isPending: isUpdatePending, mutate: update } = useMutation({
    mutationKey: [MUTATION_KEYS.AAAS_UPDATE_AAA_APP_CONFIG, { appId }],
    mutationFn: async (formValues: CharacterFormValues) => {
      if (!app?.endpoints?.apiUrl || !agentId) return;

      const resArr = await Promise.all([
        // update eliza agent
        axios.put(
          constructEndpointSafe(app?.endpoints?.apiUrl, 'agents', agentId),
          formValues.config,
          {
            headers: getBasicAuthHeaders(app),
          },
        ),
        // update backend as well
        bff.post<UpdateAAAAppRequest, AxiosResponse<UpdateAAAAppReply>>('/bff/aaa/apps/update', {
          id: agentId,
          config: JSON.stringify(formValues.config),
        }),
      ]);

      return resArr?.[0]?.data;
    },
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Metadata updated',
        desc: 'Metadata has been updated successfully.',
      });
    },
    onError: error => {
      console.error(`${[EditMetadataView.name, 'onError'].join('-')}:`, { error });
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Failed to update metadata',
        desc: error?.message ?? 'An error occurred while updating metadata.',
      });
    },
  });

  const form = useForm<CharacterFormValues>({
    mode: 'all',
    defaultValues: {
      templateId: undefined,
    },
    resolver: yupResolver(
      yup.object().shape({
        templateId: yup.string().required(),
        config: yup.object().when('templateId', {
          is: (value: string) => ['Eliza', 'ElizaBNB'].includes(value),
          then: () => characterSchema.required(),
          otherwise: () => yup.object().nullable(),
        }),
      }) as yup.ObjectSchema<CharacterFormValues>,
    ),
  });
  const formValues = form.watch();

  const { data: recentlyGeneratedCharacterResult } = usePollGeneratedCharacter({
    params: { jobId: formValues.persona?.recentJobId ?? '' },
  });

  useEffect(() => {
    if (isCurrentMetadataSuccess && isAppSuccess) {
      const character = currentMetadata?.character;

      // TODO: remove when simple mode rework is done or until we have a better solution
      delete character?.twitterProfile;

      form.setValue('templateId', app?.template?.id ?? '');
      form.setValue('persona.selectedPersonaId', character?.name?.trim()?.toLowerCase() ?? '');
      if (currentMetadata?.character) form.setValue('config', currentMetadata?.character);
    }
  }, [app, currentMetadata, form, isAppSuccess, isCurrentMetadataSuccess]);

  useEffect(() => {
    if (form.formState.errors) {
      console.log(
        `${[EditMetadataView.name, 'formState.errors'].join('-')}:`,
        form.formState.errors,
      );
    }
  }, [form.formState.errors]);

  const onValid: SubmitHandler<CharacterFormValues> = useCallback(
    formValues => {
      console.log(`${[EditMetadataView.name, onValid, 'formValues'].join('-')}:`, { formValues });

      update(formValues);
    },
    [update],
  );

  const onInvalid: SubmitErrorHandler<CharacterFormValues> = useCallback(
    errors => {
      console.error(`${[EditMetadataView.name, onInvalid.name, 'errors'].join('-')}:`, { errors });

      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Invalid form',
        desc: 'Please check the form for errors',
      });
    },
    [addAlert],
  );

  const tabs = useMemo(
    () => [
      {
        label: 'Simple Mode',
        content: (
          <ConfigSimpleMode
            agentPersonaSectionLabelEndAdornment={
              <Stack>
                <Button
                  disabled={
                    recentlyGeneratedCharacterResult &&
                    !recentlyGeneratedCharacterResult?.isFinished
                  }
                  onClick={onOpenGeneratePersonaDialog}
                >
                  Generate Persona
                </Button>
              </Stack>
            }
            currentCharacterMetadata={currentMetadata?.character}
            enableAgentPersona
            pt={3}
          />
        ),
      },
      {
        label: 'Advanced Mode',
        content: <ConfigAdvancedMode pt={3} />,
      },
    ],
    [currentMetadata?.character, onOpenGeneratePersonaDialog, recentlyGeneratedCharacterResult],
  );

  return (
    <>
      <GeneratePersonaDialog
        onClose={onCloseGeneratePersonaDialog}
        onGenerated={reply => {
          form.setValue('persona.recentJobId', reply.jobId, {
            shouldValidate: true,
            shouldTouch: false,
          });
          form.setValue('persona.bio', reply.bio, { shouldValidate: true });
          form.setValue('persona.lore', reply.lore, { shouldValidate: true });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.AAAS_GET_GENERATED_CHARACTER, reply.jobId],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.AAAS_LIST_GENERATED_CHARACTERS],
          });
        }}
        open={isOpenGeneratePersonaDialog}
      />

      <Section hasBackButton sx={{ background: '#fafafa' }} title="Edit metadata">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onValid, onInvalid)}>
            <Stack spacing={5}>
              <Stack px={{ md: 5, sm: 3, xs: 3 }} py={3}>
                {isCurrentMetadataPending || isAppPending ? (
                  <CircularProgress />
                ) : (
                  <TabbedSection tabs={tabs} />
                )}
              </Stack>

              <ButtonGroupContainer
                sx={{ borderTop: theme => `1px solid ${theme.colors.functional.subject.border}` }}
              >
                <LoadingButton
                  data-testid="edit-metadata-save-button"
                  disabled={isCurrentMetadataPending}
                  loading={form.formState.isValidating || isUpdatePending}
                  type="submit"
                >
                  <Stack direction="row" spacing={1}>
                    <IconCirclePlus sx={{ height: 18, width: 18 }} />
                    <Typography variant="bodySmallC">Save Changes</Typography>
                  </Stack>
                </LoadingButton>
              </ButtonGroupContainer>
            </Stack>
          </form>
        </FormProvider>
      </Section>
    </>
  );
};
