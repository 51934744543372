import { Chip, ChipProps, Typography } from '@mui/material';

export const PaymentStatusChip = ({ status }: { status: string }) => {
  return (
    <Chip
      color={getChipColor(status)}
      label={<Typography variant="captionM">{status}</Typography>}
      size="small"
      variant="outlined"
    />
  );
};

const getChipColor = (status: string): ChipProps['color'] => {
  switch (status) {
    case 'Healthy':
      return 'success';

    case 'Active':
      return 'primary';
    case 'Pause':
      return 'secondary';
    case 'Terminated':
      return 'error';

    case 'Pending Invoice':
      return 'warning';
    case 'Awaiting':
      return 'warning';
    case 'Paid':
      return 'primary';
    case 'Overdue':
      return 'error';

    default:
      return 'default';
  }
};
