import { handlers as _handlers } from './handlers';

export const mockEndpoints: (keyof typeof _handlers)[] = [
  'GET/bff/aaa/templates',
  'GET/bff/bill/agreements',
  'GET/bff/bill/agreements/:agreementId',
  'GET/bff/bill/payments',
  'GET/bff/bill/payments/:paymentId',
  'GET/bff/bill/payments/tracker',
];
