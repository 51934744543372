import { Dialog, DialogProps, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { DialogContainer, DialogHeader } from 'shared/components/Dialog';
import { Label } from 'shared/components/form';
import { IconCopy } from 'shared/components/icons/IconCopy';
import { AAAApp } from 'shared/types/protoc-gen/bffaaa';

interface IApiLoginDetailsDialog extends DialogProps {
  app?: AAAApp;
}

export const ApiLoginDetailsDialog: React.FC<IApiLoginDetailsDialog> = ({
  app,
  onClose,
  ...props
}) => {
  const { addAlert } = useAlerts();

  const handleClose = () => {
    onClose?.({}, 'backdropClick');
  };

  const handleCopyText = (str: string | undefined) => {
    if (!str) return;

    navigator.clipboard
      .writeText(str)
      .then(_ => addAlert({ severity: ALERT_SEVERITY.INFO, title: 'Successfully copied text' }));
  };

  return (
    <Dialog onClose={onClose} {...props}>
      <DialogContainer sx={{ minWidth: '500px' }}>
        <DialogHeader onClose={handleClose} title="Login details" />

        <Stack spacing={2}>
          <Label label="Username">
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleCopyText(app?.auth?.basicAuthUsername)}>
                      <IconCopy />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              disabled
              fullWidth
              value={app?.auth?.basicAuthUsername ?? ''}
            />
          </Label>

          <Label label="Password">
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleCopyText(app?.auth?.basicAuthPassword)}>
                      <IconCopy />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              disabled
              fullWidth
              value={app?.auth?.basicAuthPassword ?? ''}
            />
          </Label>
        </Stack>
      </DialogContainer>
    </Dialog>
  );
};
