import axios, { AxiosError, HttpStatusCode } from 'axios';
import elliptic from 'elliptic';
import { characterSchema } from 'pages/aaas/NewAgentDeployment/validation/eliza';
import { debounce } from 'perfect-debounce';
import * as yup from 'yup';

export const getValidationSchema = () =>
  yup.object().shape({
    agentPrefixName: yup
      .string()
      .required()
      .noUrl()
      .min(2, 'Agent prefix name must be at least 2 characters long.')
      .max(8, 'Agent prefix name must be at most 8 characters long.')
      .matches(/^[0-9a-z-]+$/, "Please use lowercase alphanumeric characters or '-' only.")
      .matches(/^[^-].*[^-]$/, "Cannot begin or end with '-'.")
      .matches(/^(?!.*-{2}).*$/, "'-' cannot be used consecutively.")
      .matches(/[a-z0-9]([-a-z0-9]*[a-z0-9])/, 'Does not match regex [a-z0-9]([-a-z0-9]*[a-z0-9])')
      .noLeadingDigit(),
    deploymentType: yup.string().required(),
    templateId: yup.string().required(),
    dockerImg: yup.string().when('templateId', {
      is: 'Custom',
      then: () => yup.string().required().isDockerImg(),
      otherwise: () => yup.string().nullable(),
    }),
    config: yup.object().when('templateId', {
      is: (value: string) => ['Eliza', 'ElizaBNB'].includes(value),
      then: () => characterSchema.required(),
      otherwise: () => yup.object().nullable(),
    }),

    // Agent persona character generation job ID; Just for state management
    persona: yup
      .object()
      .shape({
        bio: yup.string().nullable().optional().notRequired(),
        lore: yup.string().nullable().optional().notRequired(),
        recentJobId: yup.string().nullable().optional().notRequired(),
        selectedPersonaId: yup.string().nullable().optional().notRequired(),
      })
      .optional(),
  });

const openAiKeyRule = yup
  .string()
  .required('This field is required.')
  .test('is-valid-api-key', 'Invalid API Key', async function (value) {
    if (!value) return false;
    const errMsg = await debouncedValidateOpenAiKey(value);

    if (errMsg) {
      return this.createError({ message: errMsg });
    }

    return true; // Validation passed
  });

const ecPrivateKeyRule = yup
  .string()
  .required('This field is required.')
  .test('is-valid-ec-private-key', 'Invalid EC private key.', validateECPrivateKey);

export const getEnvVarValidationSchema = (envList: string[]) =>
  yup.object().shape(
    Object.fromEntries(
      Object.entries({
        OPENAIKEY: openAiKeyRule,
        OPENAI_API_KEY: openAiKeyRule,
        CDP_API_KEY_NAME: yup.string().required(),
        CDP_API_KEY_PRIVATE_KEY: ecPrivateKeyRule,
        CDP_PRIVATE_KEY: ecPrivateKeyRule,
        TWITTER_USERNAME: yup.string().required(),
        TWITTER_EMAIL: yup.string().email('Must be a valid email').required(),
        TWITTER_PASSWORD: yup.string().required(),
        BNB_PRIVATE_KEY: yup.string().required().privateKey(),
        BNB_PUBLIC_KEY: yup.string().required().address(),
        BSC_PROVIDER_URL: yup.string().url().required(),
      }).filter(([key]) => envList.includes(key)),
    ),
  );

function validateECPrivateKey(pemKey: string) {
  console.debug('validating');

  console.debug('pemKey: ', pemKey);

  // Step 1: Check if the key has the correct PEM header and footer
  if (
    !pemKey.includes('-----BEGIN EC PRIVATE KEY-----') ||
    !pemKey.includes('-----END EC PRIVATE KEY-----')
  ) {
    return false;
  }

  try {
    // Step 2: Extract Base64 data between BEGIN and END lines
    const base64Data = pemKey
      .replaceAll('\\n', '\n')
      .replace('-----BEGIN EC PRIVATE KEY-----', '')
      .replace('-----END EC PRIVATE KEY-----', '')
      .trim();

    // Step 3: Decode the Base64 data into a Buffer
    const buffer = new Uint8Array(
      atob(base64Data)
        .split('')
        .map(c => c.charCodeAt(0)),
    );

    // Step 4: Use elliptic to validate the private key (e.g., for the P-256 curve)
    const EC = elliptic.ec;
    const ec = new EC('secp256k1'); // Use 'p256', 'secp256k1', etc., depending on your key's curve
    const key = ec.keyFromPrivate(buffer); // This will throw if the key is invalid

    console.debug('key: ', key);

    return true;
  } catch (err: any) {
    console.error('err: ', err);

    return false;
  }
}

const validateOpenAiKey = async (apiKey: string) => {
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'user',
            content: 'Is my api key valid',
          },
        ],
      },
      { headers: { Authorization: `Bearer ${apiKey}`, model: 'gpt-4o-mini' } },
    );

    if (response?.status === 200) {
      return ''; // Valid key, no error message
    }
  } catch (_error) {
    const error = _error as AxiosError<{ error: { code: string } }>;

    const statusCode = error?.response?.status;

    if (statusCode === HttpStatusCode.TooManyRequests) {
      return 'It seems like your API key is rate limited. Please top up your openAI credits.';
    }

    if (statusCode === HttpStatusCode.Unauthorized) {
      return 'Your API key is invalid. Please check your key and try again.';
    }

    const errMsg = error?.response?.data?.error?.code;

    return errMsg
      ? `${errMsg?.charAt(0)?.toUpperCase()}${errMsg?.slice(1)?.replaceAll('_', ' ')}`
      : 'Invalid API Key';
  }
};

const debouncedValidateOpenAiKey = debounce(validateOpenAiKey, 500);
