import { LoadingButton } from '@mui/lab';
import { Box, BoxProps, Stack, Typography } from '@mui/material';
import { FC, ReactNode, useMemo } from 'react';
import {
  FieldName,
  FieldPath,
  FieldPathValue,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import IconCheckedCircle from 'shared/components/icons/IconCheckedCircle';
import IconNay from 'shared/components/icons/IconNay';
import IconUncheckedCircle from 'shared/components/icons/IconUncheckedCircle';

import { FormValues } from './types';

export function Checked({ valid }: { valid?: boolean }) {
  return valid ? (
    <IconCheckedCircle />
  ) : valid === undefined ? (
    <IconUncheckedCircle />
  ) : (
    <IconNay />
  );
}

const labelMap: Record<keyof FormValues, string> = {
  // Order of the keys of this map is also the order of appearance on the summary panel.
  imageName: 'Name:',
  imageDesc: 'Description:',
  dockerImg: 'Docker image:',
  agentLogo: 'Logo:',
  chatEndpoint: 'Chat endpoint:',
  port: 'Port:',
  githubUrl: 'Github:',
  envVars: 'Env vars:',
};

function getDisplayValue(
  fieldName: FieldName<FormValues>,
  value: FieldPathValue<FormValues, FieldPath<FormValues>>,
  isError: boolean,
): ReactNode {
  switch (fieldName) {
    case 'imageDesc':
      return typeof value === 'string' && value?.length && value?.length > 10
        ? `${value?.slice(0, 4)}...${value?.slice(-4)}`
        : String(value);
    case 'agentLogo':
      return value && <Box component="img" src={String(value)} sx={{ height: 40, width: 40 }} />;
    case 'envVars':
      return Array.isArray(value) && value?.length ? value?.map(cur => cur?.name).join(', ') : '';
    case 'chatRequestSchema':
    case 'chatResponseSchema':
      return Object.values(value || {})?.length && !isError ? 'Valid' : 'Invalid';
    default:
      return value === undefined ? '' : String(value);
  }
}

export const PublishAgentSummaryPanel: FC<
  {
    isSubmitting?: boolean;
    isSubmitDisabled?: boolean;
  } & BoxProps
> = ({ isSubmitDisabled, isSubmitting, sx, ...props }) => {
  const form = useFormContext<FormValues>();
  const formValues = form?.watch();
  const formState = useFormState({ control: form.control });

  const summaryItems = useMemo(
    () =>
      Object.entries(formValues)
        ?.map(([name, value]) => {
          const fieldName = name as keyof FormValues;

          let isError = false;
          const fieldErrors = (formState?.errors as any)?.[name];

          if (fieldErrors?.message || fieldErrors?.root?.messge) {
            isError = true;
          } else if (Array.isArray(fieldErrors)) {
            isError = fieldErrors?.some?.((cur: any) =>
              Object.values(cur || {})?.some?.(val => Boolean(val)),
            );
          } else if (Object.values(fieldErrors || {})?.length) {
            isError = true;
          }

          return {
            isError,
            fieldName,
            value: getDisplayValue(fieldName, value, isError),
            touched: Boolean(
              (formState?.touchedFields as Record<string, boolean | undefined>)?.[fieldName],
            ),
            valid: !form.getFieldState(fieldName as any)?.invalid,
            dirty: form.getFieldState(fieldName as any)?.isDirty,
          };
        })
        ?.sort(
          (a, b) =>
            Object.keys(labelMap || {})?.findIndex(cur => cur === a.fieldName) -
            Object.keys(labelMap || {})?.findIndex(cur => cur === b.fieldName),
        ),
    [formValues, formState?.errors, formState?.touchedFields, form],
  );

  const scrollToStep = (fieldName: FieldName<FormValues>) => () => {
    const el = document.getElementById(`step_${fieldName}`);

    el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Box
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.08)',
        background: 'linear-gradient(118.58deg, #F6F6F6 0%, #E7E7E7 100%)',
        m: { md: 5 },
        position: 'sticky',
        top: 40,
        ...sx,
      }}
      {...props}
    >
      <Stack alignItems="center" direction="row" gap={1} sx={{ px: 5, py: 2 }}>
        <Typography pt={5} variant="captionC">
          Summary
        </Typography>
      </Stack>
      {summaryItems?.map(cur => (
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          key={cur.fieldName}
          onClick={scrollToStep(cur.fieldName)}
          sx={{
            px: 5,
            py: 2,
            borderTop: '1px solid rgba(32, 39, 35, 0.08)',
            cursor: 'pointer',
          }}
        >
          <Checked
            valid={
              cur.valid && (cur.dirty || formState?.isSubmitted)
                ? true
                : cur.isError
                ? false
                : undefined
            }
          />
          <Typography variant="caption">
            {labelMap?.[cur?.fieldName] ?? `${cur?.fieldName}`}
          </Typography>
          {labelMap?.[cur?.fieldName] && <Typography variant="captionM">{cur?.value}</Typography>}
        </Stack>
      ))}
      <Stack
        alignItems="center"
        direction="row"
        gap={1}
        sx={{ px: 5, py: 2, borderTop: '1px solid rgba(32, 39, 35, 0.08)' }}
      >
        <LoadingButton
          disabled={isSubmitting || isSubmitDisabled}
          fullWidth
          loading={isSubmitting}
          type="submit"
          variant="contained"
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </LoadingButton>
      </Stack>
    </Box>
  );
};
