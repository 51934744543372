import { useQuery } from '@tanstack/react-query';
import { bff } from 'shared/api';
import { AgreementInfoReply } from 'shared/types/protoc-gen/bffbillsystem';
import { QUERY_KEYS } from 'shared/types/react-query';

export const useGetClient = (clientId: number) =>
  useQuery({
    queryKey: [QUERY_KEYS.ADMIN_CLIENT_DETAILS, { clientId }],
    queryFn: async () => {
      const res = await bff.get<AgreementInfoReply>(`bff/bill/agreements/${clientId}`);

      return res.data;
    },
  });
