import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { ConfigAdvancedMode, ConfigSimpleMode } from 'pages/aaas/components';
import { Character } from 'pages/aaas/components/ConfigSimpleMode/types';
import { useCreateApp, useListAppTypes, usePollGeneratedCharacter } from 'pages/aaas/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PATHS } from 'routes';
import { FormTextField } from 'shared/components/form';
import IconInfo from 'shared/components/icons/IconInfo';
import ImgWithFallback from 'shared/components/ImgWithFallback';
import { Option as FormLabel } from 'shared/components/Option';
import { SectionTitle } from 'shared/components/SectionTitle';
import TabbedSection, { TabbedSectionItem } from 'shared/components/TabbedSection';
import { ConditionalTooltip } from 'shared/components/Tooltip/ConditionalTooltip';
import { useDisclosure } from 'shared/hooks/ui';
import { useBreakpoints } from 'shared/hooks/ui/useBreakpoints';
import { BannerVariant, useBannerStore } from 'shared/stores/useBannerStore';
import { AAATemplate, CreationAAAMethod, CreationAAAParams } from 'shared/types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'shared/types/react-query';
import { tryParseJSON } from 'shared/utils/json';
import { encodeToBase64 } from 'shared/utils/strings';
import * as yup from 'yup';

import { CreditsRemainingChip } from '../components/CreditsRemainingChip';
import { PurchaseCreditsButton } from '../components/PurchaseCreditsButton';
import { templateNamesToIconsMap } from '../mappings';
import { useEnvVarsStore } from './store/useEnvVarsStore';
import {
  CreationMethodDialog,
  EnvVarsDialog,
  GeneratePersonaDialog,
  SummaryPanel,
} from './components';
import { SelectDeploymentType, SelectTemplate } from './form-steps';
import { getTemplateById } from './helpers';
import { NewAgentDeploymentLayout } from './layout';
import { DeploymentType, FormValues } from './types';
import { getValidationSchema } from './validation';

export const NewAgentDeployment = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { addAlert } = useAlerts();
  const { setBanner } = useBannerStore();
  const { md } = useBreakpoints();
  const { reset: resetEnvVarsState } = useEnvVarsStore();
  const [envVars, setEnvVars] = useState<Record<string, string>>({});
  const [searchParams] = useSearchParams();

  const {
    onClose: onCloseVerifyPromoCodeDialog,
    onOpen: onOpenVerifyPromoCodeDialog,
    open: isOpenVerifyPromoCodeDialog,
  } = useDisclosure();
  const {
    onClose: onCloseEnvVarsDialog,
    onOpen: onOpenEnvVarsDialog,
    open: envVarsDialogOpened,
  } = useDisclosure();
  const {
    onClose: onCloseGeneratePersonaDialog,
    onOpen: onOpenGeneratePersonaDialog,
    open: isOpenGeneratePersonaDialog,
  } = useDisclosure();

  const { data: templates } = useListAppTypes();
  const { isPending: deployAgentPending, mutate: deployAgent } = useCreateApp({
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.AAAS_GET_AAA_APPS] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.BFF_USER_SUBSCRIPTIONS] });

      if (data?.app) {
        addAlert({
          severity: ALERT_SEVERITY.SUCCESS,
          title: 'Agent deployment initiated',
        });

        resetEnvVarsState();
        navigate(PATHS.AAAS_DETAILS.replace(':appId', data.app.id));
      }
    },
  });

  const searchParamsTemplate = useMemo(() => searchParams?.get('template'), [searchParams]);
  const preSelectedTemplate = useMemo(
    () => getTemplateById(searchParamsTemplate || '', templates),
    [searchParamsTemplate, templates],
  );

  const form = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      templateId: searchParamsTemplate || undefined,
      deploymentType: DeploymentType.TEE,
      agentPrefixName: undefined,
    },
    resolver: yupResolver(getValidationSchema() as yup.ObjectSchema<FormValues>),
  });
  const formValues = form.watch();

  const { data: recentlyGeneratedCharacterResult } = usePollGeneratedCharacter({
    params: { jobId: formValues.persona?.recentJobId ?? '' },
  });

  const selectedTemplate = useMemo(
    () => preSelectedTemplate ?? getTemplateById(formValues.templateId ?? '', templates),
    [formValues.templateId, preSelectedTemplate, templates],
  );

  const selectedTemplateEnvList = useMemo(() => {
    let envList = [...(selectedTemplate?.envList ?? [])];

    if (formValues.config?.modelProvider?.includes('openrouter')) {
      envList = envList?.filter(
        cur =>
          !(
            cur.toUpperCase().includes('OPENAI_API_KEY') || cur.toUpperCase().includes('OPENAIKEY')
          ),
      );
    }

    if (
      ['eliza', 'elizabnb'].includes(formValues?.templateId?.toLowerCase() || '') &&
      !formValues.config?.clients?.includes('twitter')
    ) {
      envList = envList?.filter(cur => !cur.toUpperCase().includes('TWITTER'));
    }

    return envList;
  }, [
    formValues.config?.clients,
    formValues.config?.modelProvider,
    formValues.templateId,
    selectedTemplate?.envList,
  ]);

  const setTemplateFormValues = useCallback(
    (template: AAATemplate | undefined) => {
      const character: Partial<Character> = template?.configExample
        ? tryParseJSON<Character>(template?.configExample)
        : {};

      switch (String(template?.id)) {
        case 'Custom':
          form.resetField('config');
          form.reset({
            ...formValues,
            templateId: String(template?.id),
            deploymentType: formValues?.deploymentType,
            agentPrefixName: formValues?.agentPrefixName,
            dockerImg: undefined,
          });
          break;

        case 'Eliza':
          form.resetField('dockerImg');
          form.reset({
            templateId: String(template?.id),
            deploymentType: formValues?.deploymentType,
            agentPrefixName: formValues?.agentPrefixName,
            config: {
              ...character,
              modelProvider: 'openrouter',
            },
            persona: {
              selectedPersonaId: character?.name?.trim().toLowerCase(),
            },
          });
          break;

        case 'ElizaBNB':
          form.resetField('dockerImg');
          form.reset({
            templateId: String(template?.id),
            deploymentType: formValues?.deploymentType,
            agentPrefixName: formValues?.agentPrefixName,
            config: {
              ...character,
              modelProvider: 'openrouter',
            },
            persona: {
              selectedPersonaId: character?.name?.trim().toLowerCase(),
            },
          });
          break;

        case '':
          form.resetField('dockerImg');
          form.resetField('config');
          form.reset({
            deploymentType: formValues?.deploymentType,
            agentPrefixName: formValues?.agentPrefixName,
          });
          break;

        default:
          form.resetField('dockerImg');
          form.resetField('config');
          form.reset({
            templateId: String(template?.id),
            deploymentType: formValues?.deploymentType,
            agentPrefixName: formValues?.agentPrefixName,
          });
          break;
      }
    },
    [form, formValues],
  );

  const handleDeployAgent = useCallback(
    (creationMethod: CreationAAAMethod, creationParams?: CreationAAAParams) => {
      const transformedEnvVars = Object.fromEntries(
        Object.entries(envVars).map(([key, value]) => [key, encodeToBase64(String(value))]),
      );

      const formValues = form.getValues();

      deployAgent({
        templateId: selectedTemplate?.id ?? '',
        envList: transformedEnvVars,
        name: formValues.agentPrefixName ?? '',
        config: JSON.stringify(formValues.config ?? '{}', null, 2),
        creationMethod,
        creationParams,
      });
    },
    [deployAgent, envVars, form, selectedTemplate?.id],
  );

  const onValid: SubmitHandler<FormValues> = useCallback(
    formValues => {
      console.log(`${NewAgentDeployment.name} formValues:`, formValues);

      const envVarsListLength = Object.keys(selectedTemplateEnvList ?? []).length;

      if (envVarsListLength > 0) return onOpenEnvVarsDialog();

      return onOpenVerifyPromoCodeDialog();
    },
    [onOpenEnvVarsDialog, onOpenVerifyPromoCodeDialog, selectedTemplateEnvList],
  );

  const configTabs = useMemo<TabbedSectionItem[]>(
    () => [
      {
        label: 'Simple Mode',
        content: (
          <ConfigSimpleMode
            agentPersonaSectionLabelEndAdornment={
              <Stack>
                <Button
                  disabled={
                    recentlyGeneratedCharacterResult &&
                    !recentlyGeneratedCharacterResult?.isFinished
                  }
                  onClick={onOpenGeneratePersonaDialog}
                >
                  Generate Persona
                </Button>
              </Stack>
            }
            enableAgentPersona
            pt={3}
          />
        ),
      },
      {
        label: 'Advanced Mode',
        content: <ConfigAdvancedMode pt={3} />,
      },
    ],
    [onOpenGeneratePersonaDialog, recentlyGeneratedCharacterResult],
  );

  useEffect(
    () => {
      setBanner(BannerVariant.AAA_WARNING, true);
      resetEnvVarsState();

      if (preSelectedTemplate) {
        setTemplateFormValues(preSelectedTemplate);
      }

      return () => {
        setBanner(BannerVariant.AAA_WARNING, false);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [preSelectedTemplate],
  );

  return (
    <NewAgentDeploymentLayout>
      <CreationMethodDialog
        onClose={onCloseVerifyPromoCodeDialog}
        onSelectCreationMethod={handleDeployAgent}
        open={isOpenVerifyPromoCodeDialog}
      />
      <EnvVarsDialog
        currentCharacterMetadata={formValues.config}
        envList={selectedTemplateEnvList}
        onClose={onCloseEnvVarsDialog}
        onConfirmed={formValues => {
          setEnvVars(formValues);
          setTimeout(() => {
            onOpenVerifyPromoCodeDialog();
          }, 500);
        }}
        open={envVarsDialogOpened}
        template={selectedTemplate}
      />
      <GeneratePersonaDialog
        onClose={onCloseGeneratePersonaDialog}
        onGenerated={reply => {
          form.setValue('persona.recentJobId', reply.jobId, {
            shouldValidate: true,
            shouldTouch: false,
          });
          form.setValue('persona.bio', reply.bio, { shouldValidate: true });
          form.setValue('persona.lore', reply.lore, { shouldValidate: true });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.AAAS_GET_GENERATED_CHARACTER, reply.jobId],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.AAAS_LIST_GENERATED_CHARACTERS],
          });
        }}
        open={isOpenGeneratePersonaDialog}
      />

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onValid, err => console.error('err:', err))}>
          <Box margin="0 auto">
            <Grid container>
              <Grid
                item
                md={8}
                sx={{
                  '& > *:not(:first-child)': {
                    mr: {
                      xs: 3,
                      sm: 3,
                      md: 0,
                    },
                  },
                }}
                xs={12}
              >
                <SectionTitle>
                  <Grid container spacing={{ md: 0, sm: 3, xs: 3 }}>
                    <Grid item md={6} sm={6} xs={12}>
                      {searchParamsTemplate ? (
                        <>
                          <ImgWithFallback
                            src={
                              selectedTemplate?.iconLink ||
                              templateNamesToIconsMap?.[String(selectedTemplate?.id)]
                            }
                          />
                          {selectedTemplate?.name} Configuration
                        </>
                      ) : (
                        <>Build your stack</>
                      )}
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                      <Stack
                        alignItems="start"
                        direction="row"
                        justifyContent={md ? 'end' : 'start'}
                        spacing={2}
                      >
                        <CreditsRemainingChip />
                        <PurchaseCreditsButton />
                      </Stack>
                    </Grid>
                  </Grid>
                </SectionTitle>
                <SelectDeploymentType />
                <SelectTemplate onSelectTemplate={template => setTemplateFormValues(template)} />
                <FormTextField
                  data-testid="agent-prefix-name-input"
                  fieldConfig={{
                    name: 'agentPrefixName',
                    label: 'Agent prefix name',
                    placeholder: 'agent',
                  }}
                  id="step_agentPrefixName"
                  tooltipContent="Maximum of 6 characters only for the prefix name."
                />
                {formValues.templateId === 'Custom' && (
                  <FormTextField
                    fieldConfig={{
                      name: 'dockerImg',
                      label: 'Image ID',
                      placeholder: 'dockerhub_org_name/image_name:tag',
                    }}
                    id="step_dockerImg"
                  />
                )}
                {!!selectedTemplate?.configExample && (
                  <>
                    <FormLabel
                      id="step_config"
                      optionTitle={
                        <Stack direction="row" spacing={1}>
                          <Box>Config</Box>
                          <ConditionalTooltip
                            enabled
                            title={
                              'See https://github.com/ai16z/characterfile/blob/main/examples/types.d.ts for reference'
                            }
                            wrapped
                          >
                            <IconInfo />
                          </ConditionalTooltip>
                        </Stack>
                      }
                    >
                      <TabbedSection id="config-tabs" tabs={configTabs} />
                    </FormLabel>
                  </>
                )}
              </Grid>
              <Grid item md={4} xs={12}>
                <SummaryPanel
                  isSubmitting={deployAgentPending}
                  sx={{
                    mt: {
                      xs: 10,
                    },
                    m: {
                      xs: 3,
                      md: 5,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </NewAgentDeploymentLayout>
  );
};
