import { IconButton } from '@mui/material';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { IconCopy } from 'shared/components/icons/IconCopy';
import { useCopyToClipboard } from 'shared/hooks/ui';

interface IIconButtonCopy<TFieldValues extends FieldValues> {
  field: FieldPath<TFieldValues>;
}

export const IconButtonCopy = <TFieldValues extends FieldValues>({
  field,
}: IIconButtonCopy<TFieldValues>) => {
  const form = useFormContext();
  const [, copy] = useCopyToClipboard();

  return (
    <IconButton onClick={() => copy(String(form.getValues(field)))}>
      <IconCopy />
    </IconButton>
  );
};
