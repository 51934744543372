import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import dayjs, { Dayjs } from 'dayjs';
import { ClientPaymentForm } from 'pages/admin/ManageClients/ClientDetailsView/ClientPaymentForm';
import { useGetClient } from 'pages/admin/ManageClients/useGetClient';
import { ClientPaymentFormSchema } from 'pages/admin/ManageClients/validation';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { bff } from 'shared/api';
import { Section } from 'shared/components';
import {
  CreatePaymentRequest,
  EditPaymentReply,
  PaymentInfoReply,
} from 'shared/types/protoc-gen/bffbillsystem';
import { MUTATION_KEYS, QUERY_KEYS } from 'shared/types/react-query';
import { ObjectSchema } from 'yup';

interface FormValues
  extends Pick<
    CreatePaymentRequest,
    'amount' | 'currency' | 'type' | 'subType' | 'status' | 'paymentTxhash'
  > {
  paymentTime?: Dayjs;
  invoiceTime?: Dayjs;
  nextPaymentDate?: Dayjs;
  paymentPeriodFrom?: Dayjs;
  paymentPeriodTo?: Dayjs;
}

export const ClientPaymentDetailsSubView = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [editMode, setEditMode] = useState(false);

  const { clientId, paymentId } = useParams();
  const { addAlert } = useAlerts();

  const form = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      type: '',
      subType: '',
      currency: '',
      status: '',
      paymentTime: undefined,
      paymentTxhash: undefined,
      invoiceTime: undefined,
      nextPaymentDate: undefined,
      paymentPeriodFrom: undefined,
      paymentPeriodTo: undefined,
      amount: undefined,
    },
    resolver: yupResolver(ClientPaymentFormSchema as unknown as ObjectSchema<FormValues>),
  });

  const { data: client, isPending: getClientPending } = useGetClient(Number(clientId));
  const { data: payment, isPending: getPaymentPending } = useQuery({
    queryKey: [QUERY_KEYS.ADMIN_CLIENT_PAYMENT_DETAILS, { clientId, paymentId }],
    queryFn: async () => {
      const res = await bff.get<PaymentInfoReply>(`/bff/bill/payments/${paymentId}`);

      return res.data;
    },
  });

  useEffect(() => {
    if (payment) {
      form.reset({
        ...payment,
        paymentTime: payment.paymentTime ? dayjs(payment.paymentTime) : undefined,
        invoiceTime: payment.invoiceTime ? dayjs(payment.invoiceTime) : undefined,
        nextPaymentDate: payment.nextPaymentDate ? dayjs(payment.nextPaymentDate) : undefined,
        paymentPeriodFrom: payment.paymentPeriodFrom ? dayjs(payment.paymentPeriodFrom) : undefined,
        paymentPeriodTo: payment.paymentPeriodTo ? dayjs(payment.paymentPeriodTo) : undefined,
      });
    }
  }, [form, payment]);

  const { isPending: isUpdatePaymentPending, mutateAsync: updatePayment } = useMutation({
    mutationKey: [MUTATION_KEYS.ADMIN_CLIENT_UPDATE_PAYMENT, { clientId, paymentId }],
    mutationFn: async (data: FormValues) => {
      const res = await bff.put<EditPaymentReply>('/bff/bill/payments', {
        ...data,
        agreementId: Number(clientId),
        paymentId: Number(paymentId),
        paymentTime: data.paymentTime
          ? dayjs(data.paymentTime).format('YYYY-MM-DD HH:mm:ss')
          : undefined,
        invoiceTime: data.invoiceTime
          ? dayjs(data.invoiceTime).format('YYYY-MM-DD HH:mm:ss')
          : undefined,
        nextPaymentDate: data.nextPaymentDate
          ? dayjs(data.nextPaymentDate).format('YYYY-MM-DD HH:mm:ss')
          : undefined,
        paymentPeriodFrom: data.paymentPeriodFrom
          ? dayjs(data.paymentPeriodFrom).format('YYYY-MM-DD HH:mm:ss')
          : undefined,
        paymentPeriodTo: data.paymentPeriodTo
          ? dayjs(data.paymentPeriodTo).format('YYYY-MM-DD HH:mm:ss')
          : undefined,
        amount: String(data.amount ?? ''),
      });

      return res.data;
    },
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Payment updated successfully',
        desc: 'Updated a payment for client',
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ADMIN_CLIENT_PAYMENT_DETAILS, { clientId, paymentId }],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ADMIN_CLIENT_PAYMENT_LIST, { clientId }],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ADMIN_CLIENT_PAYMENT_TRACKER, { clientId }],
      });
      navigate(-1);
    },
    onError: () => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Failed to update payment',
        desc: 'Please try again',
      });
    },
  });

  return (
    <FormProvider {...form}>
      <Section
        component="form"
        hasBackButton
        onSubmit={form.handleSubmit(
          formValues => updatePayment(formValues),
          err => {
            console.error(`${ClientPaymentDetailsSubView.name} err:`, err);
          },
        )}
        renderSubHeaderEndContent={
          <Button onClick={() => setEditMode(prev => !prev)} size="small">
            {editMode ? 'Cancel' : 'Edit'}
          </Button>
        }
        renderSubHeaderStartContent={
          getClientPending || getPaymentPending ? (
            <CircularProgress
              data-testid={`${ClientPaymentDetailsSubView.name}_circular-progress`}
              size={24}
            />
          ) : undefined
        }
        title={`Payment for ${client?.clientName} (ID: ${payment?.paymentId})`}
      >
        <ClientPaymentForm
          data-testid="client-payment-form"
          submitButtonLabelText="Save"
          submitButtonLoading={isUpdatePaymentPending}
          writeMode={editMode}
        />
      </Section>
    </FormProvider>
  );
};
