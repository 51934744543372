import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import dayjs from 'dayjs';
import { ClientPaymentForm } from 'pages/admin/ManageClients/ClientDetailsView/ClientPaymentForm';
import { useGetClient } from 'pages/admin/ManageClients/useGetClient';
import { ClientPaymentFormSchema } from 'pages/admin/ManageClients/validation';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { bff } from 'shared/api';
import { Section } from 'shared/components';
import { CreatePaymentReply, CreatePaymentRequest } from 'shared/types/protoc-gen/bffbillsystem';
import { MUTATION_KEYS, QUERY_KEYS } from 'shared/types/react-query';
import { ObjectSchema } from 'yup';

interface FormValues extends Omit<CreatePaymentRequest, 'agreementId'> {}

export const ClientCreatePaymentSubView = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { clientId } = useParams();
  const { addAlert } = useAlerts();

  const form = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(ClientPaymentFormSchema as unknown as ObjectSchema<FormValues>),
  });

  const { data: client, isPending: getClientPending } = useGetClient(Number(clientId));

  const { isPending: isCreatePaymentPending, mutateAsync: createPayment } = useMutation({
    mutationKey: [MUTATION_KEYS.ADMIN_CLIENT_CREATE_PAYMENT, { clientId }],
    mutationFn: async (data: FormValues) => {
      const res = await bff.post<CreatePaymentReply>('/bff/bill/payments', {
        ...data,
        agreementId: Number(clientId),
        paymentTime: data.paymentTime
          ? dayjs(data.paymentTime).format('YYYY-MM-DD HH:mm:ss')
          : undefined,
        invoiceTime: data.invoiceTime
          ? dayjs(data.invoiceTime).format('YYYY-MM-DD HH:mm:ss')
          : undefined,
        nextPaymentDate: data.nextPaymentDate
          ? dayjs(data.nextPaymentDate).format('YYYY-MM-DD HH:mm:ss')
          : undefined,
        paymentPeriodFrom: data.paymentPeriodFrom
          ? dayjs(data.paymentPeriodFrom).format('YYYY-MM-DD HH:mm:ss')
          : undefined,
        paymentPeriodTo: data.paymentPeriodTo
          ? dayjs(data.paymentPeriodTo).format('YYYY-MM-DD HH:mm:ss')
          : undefined,
        amount: String(data.amount ?? ''),
      });

      return res.data;
    },
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Payment created successfully',
        desc: 'Added a new payment for client',
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ADMIN_CLIENT_PAYMENT_LIST, { clientId }],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ADMIN_CLIENT_PAYMENT_TRACKER, { clientId }],
      });

      form.reset();
      navigate(-1);
    },
    onError: () => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Failed to create payment',
        desc: 'Please try again',
      });
    },
  });

  return (
    <FormProvider {...form}>
      <Section
        component="form"
        hasBackButton
        onSubmit={form.handleSubmit(
          formValues => createPayment(formValues),
          err => {
            console.error(`${ClientCreatePaymentSubView.name} err:`, err);
          },
        )}
        renderSubHeaderStartContent={getClientPending ? <CircularProgress size={24} /> : undefined}
        title={`Add payment for ${client?.clientName}`}
      >
        <ClientPaymentForm
          submitButtonLabelText="Create Payment"
          submitButtonLoading={isCreatePaymentPending}
          writeMode
        />
      </Section>
    </FormProvider>
  );
};
