import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

interface IIconAltLayerFlat extends SvgIconProps {
  fillColor?: string;
}

export const IconAltLayerFlat: FC<IIconAltLayerFlat> = ({
  fillColor,
  height = '12',
  width = '12',
  ...props
}) => (
  <SvgIcon
    fill="none"
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M1.0528 7.96496C0.929718 8.06496 0.930431 8.25307 1.05427 8.35214L5.02046 11.5251C5.18328 11.6554 5.42448 11.5394 5.42448 11.3309V9.8589V7.8695L5.42448 4.93543C5.42448 4.72603 5.1815 4.61038 5.01899 4.74242L1.0528 7.96496Z"
      fill={fillColor ?? '#898897'}
      fillRule="evenodd"
    />
    <path
      d="M1.49275 6.71361L0.899726 6.23919C0.77589 6.14012 0.775177 5.95201 0.898259 5.852L5.01855 2.50427C5.18107 2.37222 5.42404 2.48787 5.42404 2.69727V3.65352C5.42404 3.7284 5.3903 3.7993 5.33218 3.84652L1.80491 6.71242C1.71409 6.78621 1.58412 6.7867 1.49275 6.71361Z"
      fill={fillColor ?? '#898897'}
    />
    <path
      d="M0.693427 4.08479L1.33882 4.6011C1.43019 4.6742 1.56016 4.6737 1.65097 4.59992L5.33235 1.6088C5.39047 1.56158 5.42421 1.49068 5.42421 1.4158V0.429196C5.42421 0.22068 5.18301 0.104754 5.02019 0.235013L0.693427 3.69642C0.568988 3.79597 0.568988 3.98524 0.693427 4.08479Z"
      fill={fillColor ?? '#898897'}
    />
    <path
      d="M6.34615 11.4239L11.714 6.05594C11.8111 5.95883 11.8111 5.80137 11.714 5.70426L6.34615 0.336456C6.18949 0.1798 5.92163 0.290751 5.92163 0.512297V11.248C5.92163 11.4696 6.18949 11.5805 6.34615 11.4239Z"
      fill={fillColor ?? '#898897'}
    />
  </SvgIcon>
);
