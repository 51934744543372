import { LoadingButton } from '@mui/lab';
import { FormHelperText, Grid, Skeleton, Stack, StackProps, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { PERMISSIONS, useAuthContext } from 'contexts/AuthContext';
import dayjs from 'dayjs';
import { FULFILLMENT_CHANNEL_ID } from 'pages/aaas/AgentCredits/constants';
import { useListCustomerSubs, useListProductPaymentLinks } from 'pages/aaas/hooks';
import { AAAS_PATHS } from 'pages/aaas/routes';
import { FC, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useTeamMembers from 'shared/hooks/useTeamMembers';
import { QUERY_KEYS } from 'shared/types/react-query';
import { constructURL, formatUsd } from 'shared/utils/strings';

const DATE_FORMAT = 'YYYY MMM DD';

export const PurchaseCreditsView = () => {
  const { user } = useAuthContext();
  const { orgAdminEmail } = useTeamMembers();
  const isOrgCreator = user?.permissions?.includes(PERMISSIONS.WRITE_ORG_SUBSCRIPTIONS);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { addAlert } = useAlerts();

  const { data: productsData, isPending: isGetProductsPending } = useListProductPaymentLinks();
  const { data: customerSubsData, isPending: isGetCustomerSubsPending } = useListCustomerSubs();

  useEffect(() => {
    const channel = new BroadcastChannel(FULFILLMENT_CHANNEL_ID);

    // Listen for messages from other tabs
    channel.onmessage = event => {
      const fulfilled = event.data === 'fulfilled';

      if (fulfilled) {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.BFF_PRODUCT_PAYMENT_LINKS] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.BFF_USER_SUBSCRIPTIONS] });

        addAlert({
          severity: ALERT_SEVERITY.SUCCESS,
          title: 'Payment fulfilled',
          desc: 'Your payment has been received.',
          timeout: Infinity,
        });
        navigate(AAAS_PATHS.AAAS_DEPLOYMENTS, { replace: true });
      }
    };

    // Unsubscribe from the channel when the component unmounts
    return () => {
      channel.close();
    };
  }, [addAlert, navigate, queryClient]);

  const hasSubscriptions = Boolean(customerSubsData?.subscriptions?.length);

  const totalAgentCredits = useMemo(
    () => Number(customerSubsData?.totalCredits ?? 0),
    [customerSubsData?.totalCredits],
  );
  const customerSubData = useMemo(() => customerSubsData?.subscriptions?.[0], [customerSubsData]);
  const customerSubProductPrice = useMemo(
    () => Number(customerSubData?.productPrice ?? 0),
    [customerSubData],
  );
  const agentProduct = useMemo(
    () => productsData?.products.find(product => product.name === 'AAA Agent'),
    [productsData],
  );
  const currentSubPeriod = useMemo(() => {
    if (!hasSubscriptions) {
      return 'None';
    }

    return `${dayjs(Number(customerSubData?.currentPeriodStart ?? 0) * 1000).format(
      DATE_FORMAT,
    )} - ${dayjs(Number(customerSubData?.currentPeriodEnd ?? 0) * 1000).format(DATE_FORMAT)}`;
  }, [customerSubData, hasSubscriptions]);
  const monthlySubCost = useMemo(
    () =>
      `${formatUsd(customerSubProductPrice)} * ${totalAgentCredits} = ${formatUsd(
        totalAgentCredits * customerSubProductPrice,
      )}`,
    [customerSubProductPrice, totalAgentCredits],
  );

  return (
    <Grid container spacing={3} sx={{ p: 5 }}>
      <Grid item md={8} xs={12}>
        <Stack
          alignItems="center"
          justifyContent="center"
          p={5}
          spacing={5}
          sx={{
            border: theme => `1px solid ${theme.colors.functional.subject.border}`,
            background: '#fff',
            height: '100%',
          }}
        >
          <Stack alignItems="center" justifyContent="center" spacing={3}>
            <Typography variant="captionC">Monthly limit</Typography>

            <Stack alignItems="center" direction="row" justifyContent="center" spacing={3}>
              {isGetCustomerSubsPending ? (
                <>
                  <Skeleton height="100px" variant="text" width="300px" />
                </>
              ) : (
                <>
                  <Typography variant="h3">{totalAgentCredits}</Typography>

                  {!!totalAgentCredits && (
                    <Typography variant="h3">
                      ({Number(customerSubsData?.usedCredit ?? 0)} Used)
                    </Typography>
                  )}
                </>
              )}
            </Stack>
          </Stack>

          <Typography fontStyle="italic" textAlign="center" variant="captionM">
            {`*Decreases in ACs will only be enacted after the current subscription period is over.`}
          </Typography>
        </Stack>
      </Grid>

      <Grid item md={4} xs={12}>
        <Stack spacing={3}>
          <Stack
            sx={{
              background: theme => theme.colors.schema.metalPrimary,
              border: theme => `1px solid ${theme.colors.functional.subject.border}`,
              '& > *:not(:last-child)': {
                borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
              },
              py: 1,
              px: 3,
            }}
          >
            <Field
              isLoading={isGetCustomerSubsPending}
              label={'Current subscription period'}
              value={currentSubPeriod}
            />
            {hasSubscriptions && (
              <Field
                isLoading={isGetCustomerSubsPending}
                label={'Monthly subscription cost'}
                value={monthlySubCost}
              />
            )}
          </Stack>

          <LoadingButton
            data-testid="subscribe-button"
            disabled={!agentProduct?.link || !isOrgCreator}
            fullWidth
            href={`${
              agentProduct?.link
                ? hasSubscriptions
                  ? customerSubData?.customerPortalLink
                  : constructURL(agentProduct?.link, {
                      prefilled_email: user?.email ?? '',
                      utm_source: window.origin,
                      utm_medium: 'wizard',
                    })
                : '#'
            }`}
            loading={isGetProductsPending}
            rel="noreferrer noopener"
            target="_blank"
            variant="contained"
          >
            {hasSubscriptions ? 'Manage subscription' : 'Subscribe'}
          </LoadingButton>
          {!isOrgCreator && (
            <FormHelperText sx={{ '&&': { color: theme => theme.colors.functional.text.primary } }}>
              Please contact your organization owner {orgAdminEmail ? `(${orgAdminEmail}) ` : ''}if
              you need to modify your subscription.
            </FormHelperText>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

const Field: FC<StackProps & { label: string; value: string; isLoading?: boolean }> = ({
  isLoading,
  label,
  value,
  ...props
}) => {
  return (
    <Stack
      py={2}
      sx={{
        background: theme => theme.colors.schema.metalPrimary,
      }}
      {...props}
    >
      <Stack spacing={1}>
        <Typography variant="caption">{label}</Typography>
        {isLoading ? (
          <Skeleton height="18px" variant="text" width="30%" />
        ) : (
          <Typography variant="captionC">{value}</Typography>
        )}
      </Stack>
    </Stack>
  );
};
