import React from 'react';
import { FieldPath, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { NumericStepper, NumericStepperProps } from 'shared/components/NumericStepper';

import { FormTextField, IFormTextField } from './FormTextField';

interface IFormNumericTextField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends IFormTextField<TFieldValues, TName> {
  numericStepperProps?: Partial<NumericStepperProps>;
  endAdornment?: React.ReactNode;
}

export const FormNumericTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  endAdornment,
  fieldConfig,
  numericStepperProps,
  ...textFieldProps
}: IFormNumericTextField<TFieldValues, TName>) => {
  const { control, setValue } = useFormContext();
  const fieldValue = useWatch({ control, name: fieldConfig.name });
  const setFieldValue = (newValue: TFieldValues[TName]) =>
    setValue(fieldConfig.name, newValue, { shouldTouch: true, shouldDirty: true });

  return (
    <FormTextField
      InputProps={{
        type: 'text',
        endAdornment: (
          <>
            {endAdornment}
            <NumericStepper
              setValue={(newValue: string) => setFieldValue(newValue as TFieldValues[TName])}
              value={fieldValue}
              {...numericStepperProps}
            />
          </>
        ),
      }}
      fieldConfig={fieldConfig}
      type="number"
      {...textFieldProps}
    />
  );
};
