import { ArrowOutward } from '@mui/icons-material';
import { Box, Button, Skeleton, Stack, StackProps, Typography } from '@mui/material';
import { showExperimentalFeatures } from 'pages/raas/DeploymentDetails/helpers/visibility';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubHeader } from 'shared/components/SubHeader';
import { NavigationItem } from 'shared/types/navigation';

export interface IFixedSideNavigationBar extends Omit<StackProps, 'children'> {
  sideNavigationItems: NavigationItem[];
  startAdornment?: ReactNode;
  loading?: boolean;
  primaryColor: string;
}

export const FixedSideNavigationBar = ({
  loading,
  primaryColor,
  sideNavigationItems,
  startAdornment,
  ...props
}: IFixedSideNavigationBar) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Stack flexDirection="column" width={'504px'} {...props}>
      {startAdornment}

      {loading
        ? Array.from({ length: 4 }).map((_, idx) => (
            <Box key={idx.toString()}>
              <SubHeader
                sx={theme => ({
                  background: theme.colors.gradients.metal,
                  borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
                })}
              >
                <Skeleton variant="text" width="65%" />
              </SubHeader>
              <Stack flex="1" py="12px">
                {Array.from({ length: 4 }).map((_, itemsIdx) => (
                  <Skeleton
                    key={[idx, itemsIdx].join('-')}
                    sx={{
                      mx: '40px',
                      my: '12px',
                    }}
                  />
                ))}
              </Stack>
            </Box>
          ))
        : sideNavigationItems.map((sideNavigationItem, index) => (
            <Box
              id={sideNavigationItem.id}
              key={sideNavigationItem.label}
              sx={{
                mb: sideNavigationItems.length - 1 === index ? '100px' : 0,
              }}
            >
              <SubHeader
                id={sideNavigationItem.id}
                sx={theme => ({
                  background: theme.colors.gradients.metal,
                  borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
                })}
              >
                <Typography variant="bodySmallC">{sideNavigationItem.label}</Typography>
              </SubHeader>
              <Stack flex="1" py="12px">
                {sideNavigationItem.items
                  .filter(item => (!showExperimentalFeatures ? !item.hidden : true))
                  .map(item => (
                    <Button
                      key={item.label}
                      onClick={() =>
                        item.href ? window.open(item.href, '_blank') : navigate(item.to)
                      }
                      size={'small'}
                      sx={theme => ({
                        color: theme.colors.functional.text.primary,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        direction: 'row',
                        cursor: 'pointer',
                        px: '40px',
                        py: '12px',
                        background: location.pathname.includes(item.to)
                          ? primaryColor
                          : 'transparent',
                        '&:hover': {
                          background: location.pathname.includes(item.to)
                            ? primaryColor
                            : '#f7f7f7',
                        },
                      })}
                      variant="contained"
                    >
                      <Typography variant="body1">{item.label}</Typography>

                      {item.href && <ArrowOutward sx={{ height: '18px', width: '18px' }} />}
                    </Button>
                  ))}
              </Stack>
            </Box>
          ))}
    </Stack>
  );
};
