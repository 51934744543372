import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box, Grid, Paper, PaperProps, Typography, useTheme } from '@mui/material';
import { ALERT_SEVERITY, getAlertColor } from 'contexts/AlertsContext';
import Duration from 'shared/components/Duration';
import LinkArrow from 'shared/components/LinkArrow';

const Toast: React.FC<
  {
    duration?: number;
    onClose?: () => void;
    severity: ALERT_SEVERITY;
    title: string;
    link?: {
      url: string;
      label: string;
    };
    desc?: string;
  } & PaperProps
> = ({ desc, duration = 5000, link, onClose, severity, title, ...props }) => {
  const theme = useTheme();
  const color = getAlertColor(severity, theme);

  return (
    <Paper
      data-testid="toast-paper"
      id="toast-paper"
      sx={{
        position: 'relative',
        width: { xs: 360, md: 400 },
        maxWidth: 'calc(100vw - 16px)',
        padding: 2.5,
        background: theme.colors.functional.container.default,
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
        borderRadius: 1,
      }}
      {...props}
    >
      <Grid container>
        {severity === ALERT_SEVERITY.SUCCESS ? (
          <CheckCircleOutlinedIcon color="success" />
        ) : (
          <ErrorOutlineOutlinedIcon
            sx={{
              color,
            }}
          />
        )}
        <Box flex={1} marginLeft={1.25}>
          <Grid alignItems="center" container justifyContent="space-between">
            <Typography
              data-testid="toast-title"
              id="toast-title"
              sx={{
                paddingRight: '26px',
                wordBreak: 'break-word',
                color,
              }}
              variant="body2"
            >
              {title}
            </Typography>
            <CloseIcon
              data-testid="toast-close-button"
              id="toast-close-button"
              onClick={onClose}
              sx={{
                right: '22px',
                top: '22px',
                position: 'absolute',
                cursor: 'pointer',
                fill: theme.colors.functional.subject.third,
                height: '19px',
                width: '19px',
              }}
            />
          </Grid>
          {link && (
            <LinkArrow
              data-testid="toast-link"
              id="toast-link"
              label={link.label}
              rel="noopener noreferrer"
              sx={{
                marginTop: '10px',
                color: theme.colors.functional.text.link,
              }}
              target="_blank"
              to={link.url}
            />
          )}
          {desc && (
            <Typography
              data-testid="toast-description"
              id="toast-description"
              sx={{
                marginTop: '16px',
                color: theme.colors.functional.text.third,
                wordBreak: 'break-word',
                maxHeight: '150px',
                textOverflow: 'ellipsis',
                paddingRight: '12px',
                scrollbarColor: 'rebeccapurple green',
                scrollbarWidth: 'thin',
                overflowY: 'auto',
              }}
              variant="body1"
            >
              {desc}
            </Typography>
          )}
        </Box>
      </Grid>
      {duration !== Infinity && (
        <Box bottom="0px" left="0px" position="absolute" right="0px">
          <Duration delay={duration / 1000} severity={severity} />
        </Box>
      )}
    </Paper>
  );
};

export default Toast;
