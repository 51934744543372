import { Box, Button, Grid, GridProps, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { IconAaa } from 'shared/components/icons/IconAaa';
import { IconAvs } from 'shared/components/icons/IconAvs';
import { IconRaaS } from 'shared/components/icons/IconRaaS';

interface LayerCardProps extends Omit<GridProps, 'title'> {
  path: string;
  icon: ReactNode;
  title: ReactNode;
  description: string;
}

function LayerCard({ description, icon, path, sx, title, ...props }: LayerCardProps) {
  const navigate = useNavigate();

  return (
    <Grid
      alignItems="center"
      container
      item
      justifyContent="center"
      lg={4}
      onClick={() => navigate(path)}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          filter: 'brightness(0.95)',
          '& > .MuiButton-root > .MuiStack-root > *:last-child': {
            transform: 'scale(1.1)',
          },
        },

        ...sx,
      }}
      xs={12}
      {...props}
    >
      <Button
        sx={{
          '&, &:hover': {
            background: 'transparent',
            color: theme => theme.colors.functional.text.primary,
          },
          height: '100%',
          width: '100%',
        }}
      >
        <Stack alignItems="center" spacing={9}>
          {icon}
          <Box>
            <Typography textAlign="center" variant="h3">
              {title}
            </Typography>
            <Typography mt={2} textAlign="center" variant="body2">
              {description}
            </Typography>
          </Box>
          <Box
            component="img"
            src="/imgs/iconArrowCircleFilled.svg"
            sx={{
              transition: 'transform 0.5s',
              cursor: 'pointer',
              width: 56,
              height: 56,
            }}
          />
        </Stack>
      </Button>
    </Grid>
  );
}

export function SelectLayerType() {
  return (
    <Grid
      alignItems="center"
      container
      justifyContent="center"
      sx={{ bgcolor: '#fafafa', height: '80vh', '&>*': { height: '100%', cursor: 'pointer' } }}
    >
      <LayerCard
        data-testid="autonome-link"
        description="Autonomous AI Agent"
        icon={<IconAaa sx={{ width: '80px', height: '80px' }} />}
        path={PATHS.AAAS_DEPLOYMENTS}
        sx={{
          background: theme => theme.colors.gradients.cream,
        }}
        title="Autonome"
      />
      <LayerCard
        data-testid="avs-link"
        description="Deploy and manage your AVS"
        icon={<IconAvs sx={{ width: '80px', height: '80px' }} />}
        path={PATHS.AVS_DEPLOYMENTS}
        sx={{ background: theme => theme.colors.gradients.water }}
        title="AVS"
      />
      <LayerCard
        data-testid="raas-link"
        description="Deploy and manage your Rollup"
        icon={<IconRaaS sx={{ mx: 'auto', width: '80px', height: '81px' }} />}
        path={PATHS.RAAS_DEPLOYMENTS}
        sx={{ background: theme => theme.colors.gradients.leaf }}
        title="RaaS"
      />
    </Grid>
  );
}
