import { Outlet, RouteObject } from 'react-router-dom';

import { ClientCreateView } from './ClientCreateView';
import {
  ClientCreatePaymentSubView,
  ClientDetailsSubView,
  ClientPaymentDetailsSubView,
} from './ClientDetailsView';
import { ManageClients } from './ManageClients';

export const routes: RouteObject[] = [
  {
    path: '',
    element: <ManageClients />,
  },
  {
    path: ':clientId',
    element: <Outlet />,
    children: [
      { path: '', element: <ClientDetailsSubView /> },
      {
        path: 'payments',
        element: <Outlet />,
        children: [
          {
            path: 'create',
            element: <ClientCreatePaymentSubView />,
          },
          {
            path: ':paymentId',
            element: <ClientPaymentDetailsSubView />,
          },
        ],
      },
    ],
  },
  {
    path: 'create',
    element: <ClientCreateView />,
  },
];
