import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
} from '@mui/material';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { AutocompleteOption } from 'shared/components/CustomAutocomplete';
import { FormFieldConfig } from 'shared/components/form/fields/types';
import { timeZoneOptions } from 'shared/utils/dates';

interface IFormTimezonePickerField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  fieldConfig: FormFieldConfig<TFieldValues, TName>;
  disabled?: boolean;
}

export const FormTimezonePickerField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  disabled,
  fieldConfig,
}: IFormTimezonePickerField<TFieldValues, TName>) => {
  const [useLocalTimezone, setUseLocalTimezone] = useState(true);

  // TODO: remove this once BE supports timezone, should handle state in form context
  const [selectedTimezone, setSelectedTimezone] = useState<AutocompleteOption | undefined>();

  const localTimezone = useMemo(
    () =>
      timeZoneOptions.find(
        (cur: AutocompleteOption) =>
          cur.value === Intl.DateTimeFormat().resolvedOptions()?.timeZone,
      ),
    [],
  );

  const toggleLocalTimezone = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;

      setUseLocalTimezone(checked);

      if (checked) {
        setSelectedTimezone(localTimezone);
      }
    },
    [localTimezone, setSelectedTimezone],
  );

  return (
    <Controller
      name={fieldConfig.name}
      render={({ field, fieldState }) => {
        return (
          <FormControl fullWidth>
            <Autocomplete
              {...field}
              disabled={disabled || useLocalTimezone}
              getOptionLabel={(option: AutocompleteOption) => option?.label || ''}
              isOptionEqualToValue={(option, selected) => {
                const noOptionSelected = selected === null;

                return option?.value === selected?.value || noOptionSelected;
              }}
              onChange={(_, selectedOption?: AutocompleteOption | null) => {
                // TODO: remove this once BE supports timezone, should handle state in form context
                setSelectedTimezone(selectedOption || undefined);
              }}
              options={timeZoneOptions}
              renderInput={params => {
                return <TextField {...params} hiddenLabel placeholder={'Select a timezone'} />;
              }}
              renderOption={(props, option: any) => {
                return <li {...props}>{option.label}</li>;
              }}
              sx={{
                '.MuiAutocomplete-inputRoot': {
                  px: 3,
                  py: 2,
                },
              }}
              value={useLocalTimezone ? localTimezone : selectedTimezone}
            />
            <FormHelperText error={!!fieldState.error}>{fieldState.error?.message}</FormHelperText>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useLocalTimezone}
                  disabled={disabled}
                  onChange={toggleLocalTimezone}
                />
              }
              label="Use current timezone"
              labelPlacement="end"
            />
          </FormControl>
        );
      }}
    />
  );
};
