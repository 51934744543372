import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

export default function InlineCode({ children }: PropsWithChildren) {
  return (
    <Box
      component="code"
      sx={{
        px: 0.5,
        background: theme => theme.colors.functional.container.disabled,
      }}
    >
      {children}
    </Box>
  );
}
