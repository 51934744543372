import { DatePickerProps } from '@mui/lab';
import { FormControl, FormHelperText } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { FormFieldConfig } from 'shared/components/form/fields/types';

type IFormSelectDateField<TDate> = DatePickerProps<TDate> & {
  fieldConfig: FormFieldConfig;
  controllerProps?: Partial<ControllerProps>;
};

export const FormSelectDateField = <TDate,>({
  controllerProps,
  fieldConfig,
  sx,
  ...datePickerProps
}: IFormSelectDateField<TDate>) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={fieldConfig.name}
      render={({ field, fieldState }) => {
        return (
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                {...field}
                format="DD/MM/YYYY"
                sx={{
                  width: '100%',
                  background: '#fff',
                  border: theme =>
                    fieldState.error ? `1px solid ${theme.colors.schema.error}` : undefined,
                  ...sx,
                }}
                {...datePickerProps}
              />
            </LocalizationProvider>
            <FormHelperText error={Boolean(fieldState.error || fieldState.invalid)}>
              {fieldState.error?.message}
            </FormHelperText>
          </FormControl>
        );
      }}
      {...controllerProps}
    />
  );
};
