import * as yup from 'yup';

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

const mediaSchema = yup.object({
  id: yup
    .string()
    .required(
      'The `id` property is required in Media. See link https://github.com/ai16z/characterfile/blob/main/examples/types.d.ts#L10 for reference.',
    ),
  url: yup
    .string()
    .url('The `url` property in Media has invalid URL format.')
    .required(
      'The `url` property is required in Media. See link https://github.com/ai16z/characterfile/blob/main/examples/types.d.ts#L10 for reference.',
    ),
  title: yup
    .string()
    .required(
      'The `title` property is required in Media. See link https://github.com/ai16z/characterfile/blob/main/examples/types.d.ts#L10 for reference.',
    ),
  source: yup
    .string()
    .required(
      'The `source` property is required in Media. See link https://github.com/ai16z/characterfile/blob/main/examples/types.d.ts#L10 for reference.',
    ),
  description: yup.string().optional(),
  text: yup.string().optional(),
});

const contentSchema = yup.object({
  text: yup
    .string()
    .required(
      'The `text` property is required in Content. See link https://github.com/ai16z/characterfile/blob/main/examples/types.d.ts#L22 for reference.',
    ),
  action: yup.string().optional(),
  source: yup.string().optional(),
  url: yup
    .string()
    .url(
      'The `url` property in Content has an invalid URL format. See link https://github.com/ai16z/characterfile/blob/main/examples/types.d.ts#L22 for reference.',
    )
    .optional(),
  inReplyTo: yup
    .string()
    .matches(
      UUID_REGEX,
      'The `inReplyTo` property in Content has an invalid UUID format. See link https://github.com/ai16z/characterfile/blob/main/examples/types.d.ts#L22 for reference.',
    )
    .optional(),
  attachments: yup.array().of(mediaSchema).optional(),
});

const messageExampleSchema = yup.object({
  user: yup
    .string()
    .required(
      'The `user` property in MessageExample is required. See link https://github.com/ai16z/characterfile/blob/main/examples/types.d.ts#L35 for reference.',
    ),
  content: contentSchema.required('The `content` property in MessageExample is required.'),
});

export const characterSchema = yup.object({
  id: yup.string().matches(UUID_REGEX, 'The `id` property has invalid UUID format.').optional(),
  name: yup.string().required('The `name` property is required.'),
  bio: stringOrArrayOfStringRule(
    'The `bio` property must be a string or an array of strings.',
    true,
  ).required('The `bio` property is required.'),
  lore: stringOrArrayOfStringRule(
    'The `lore` property must be a string or an array of strings.',
  ).required('The `lore` property is required.'),
  topics: stringOrArrayOfStringRule(
    'The `topics` property must be a string or an array of strings.',
  ).required('The `topics` property is required.'),
  clients: yup
    .array()
    .of(
      yup
        .string()
        .oneOf(['twitter'], 'On the `clients` property can accept only "twitter" for now.'),
    )
    .required('The `clients` property is required.'),
  modelProvider: yup
    .string()
    .oneOf(
      ['openai', 'openrouter'],
      'The `modelProvider` property must be either "openai" or "openrouter".',
    )
    .required('The `modelProvider` property is required.'),
  messageExamples: yup
    .array()
    .of(yup.array().of(messageExampleSchema))
    .required(
      'The `messageExamples` property is required and should be an array of MessageExample. See link https://github.com/ai16z/characterfile/blob/main/examples/types.d.ts#L35 for reference.',
    ),
  postExamples: stringOrArrayOfStringRule(
    'The `postExamples` property must be a string or an array of strings.',
  ).required('The `postExamples` property is required.'),
  people: yup
    .array()
    .of(yup.string())
    .default([])
    .required('The `people` property is required and should be an array of strings.'),
  plugins: yup
    .array()
    .of(yup.string())
    .default([])
    .required(`The 'plugins' property is required and should be an array of strings.`),
  adjectives: yup
    .array()
    .of(yup.string())
    .required('The `adjectives` property is required and should be an array of strings.'),
  settings: yup
    .object({
      secrets: yup.object().required('The `settings.secrets` property is required'),
      voice: yup
        .object({
          model: yup.string().required('The `settings.voice.model` property is required'),
          url: yup
            .string()
            .url('The `settings.voice.url` property has an invalid URL format')
            .optional(),
        })
        .required('The `settings.voice` property is required'),
      model: yup.string().optional(),
      embeddingModel: yup.string().optional(),
    })
    .required('The `settings` property is required'),
  style: yup.object({
    all: stringOrArrayOfStringRule(
      'The `style.all` property must be a string or an array of strings.',
    ).required('The `style.all` property is required.'),
    chat: stringOrArrayOfStringRule(
      'The `style.chat` property must be a string or an array of strings.',
    ).required('The `style.chat` property is required.'),
    post: stringOrArrayOfStringRule(
      'The `style.post` property must be a string or an array of strings.',
    ).required('The `style.post` property is required.'),
  }),
});

export function stringOrArrayOfStringRule(
  message: string = 'This field must be a string or an array of strings.',
  required = false,
) {
  return yup.mixed().test('isStringOrStringArray', message, value => {
    if (value === undefined || value === null) {
      return !required; // Valid if not required
    }

    if (typeof value === 'string') {
      return required ? value.trim().length > 0 : true; // Ensure non-empty string if required
    }

    if (Array.isArray(value)) {
      return value.every(item => typeof item === 'string' && (!required || item.trim().length > 0));
    }

    return false; // Invalid if neither a string nor an array
  });
}
