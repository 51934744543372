import { Divider, Stack, StackProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

export interface IFormGroupContainer extends StackProps {
  title: string;
  endAdornment?: ReactNode;
}

export const FormGroupContainer: FC<IFormGroupContainer> = ({
  children,
  endAdornment,
  sx,
  title,
  ...props
}) => {
  return (
    <Stack
      id="form-group-container"
      spacing={2}
      sx={{
        ...sx,
      }}
      {...props}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="h5">{title}</Typography>

        {endAdornment}
      </Stack>

      <Divider />

      <Stack spacing={1}>{children}</Stack>
    </Stack>
  );
};
