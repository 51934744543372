import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { FormFieldConfig } from 'shared/components/form/fields/types';

export interface FormSelectItem {
  label: string;
  value: string | number | readonly string[] | undefined;
  description?: string;
}

type IFormSelectField<T extends FormSelectItem> = {
  items: T[];
  renderItems?: (items: T[]) => ReactNode;
  fieldConfig: FormFieldConfig;
  controllerProps?: Partial<ControllerProps>;
  loading?: boolean;
  inputLabel?: string;
  helperText?: string;
} & SelectProps<T>;

export const FormSelectField = <T extends FormSelectItem>({
  controllerProps,
  fieldConfig,
  helperText,
  inputLabel,
  items,
  loading = false,
  renderItems,
  ...selectProps
}: IFormSelectField<T>) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={fieldConfig.name}
      render={({ field, fieldState }) => (
        <FormControl data-testid={`${fieldConfig.name}_formControl`} fullWidth variant="outlined">
          {inputLabel && <InputLabel>{inputLabel}</InputLabel>}
          <Select<T>
            {...field}
            error={!!fieldState.error || fieldState.invalid}
            fullWidth
            inputProps={{
              endAdornment: loading ? <CircularProgress size={16} /> : undefined,
            }}
            inputRef={field.ref}
            onBlur={() => field.onBlur()}
            placeholder={fieldConfig.placeholder}
            ref={undefined}
            sx={{
              background: '#fff',
              minHeight: '68px',
              '& .MuiSelect-select': {
                px: 3,
              },
            }}
            value={field.value}
            {...selectProps}
          >
            {renderItems
              ? renderItems(items)
              : items.map(item => (
                  <MenuItem
                    id={`${fieldConfig.name}_menuItem`}
                    key={String(item.value)}
                    sx={{ p: 3, display: 'flex', alignItems: 'center', justifyContent: 'start' }}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                ))}
          </Select>
          <FormHelperText error={!!fieldState.error} sx={{ pl: 1.5 }}>
            <Typography fontSize={12} variant="caption">
              {helperText || fieldState.error?.message}
            </Typography>
          </FormHelperText>
        </FormControl>
      )}
      {...controllerProps}
    />
  );
};
