import { BoxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { FormTextFieldBase, IFormTextFieldBase } from 'shared/components/form';
import { Option } from 'shared/components/Option';
import { WithInfo } from 'shared/components/WithInfo';

export interface IFormTextField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends IFormTextFieldBase<TFieldValues, TName> {
  tooltipContent?: ReactNode;
  optionProps?: BoxProps;
  hideLabel?: boolean;
  label?: ReactNode;
}

export const FormTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  component,
  fieldConfig,
  hideLabel,
  label,
  optionProps,
  tooltipContent,
  ...props
}: IFormTextField<TFieldValues, TName>) => {
  const renderTextField = () => (
    <FormTextFieldBase<TFieldValues, TName>
      component={component}
      fieldConfig={fieldConfig}
      {...props}
    />
  );

  return hideLabel ? (
    renderTextField()
  ) : label ? (
    <>
      {label}
      {renderTextField()}
    </>
  ) : (
    <Option
      optionTitle={
        tooltipContent ? (
          <WithInfo
            info={tooltipContent}
            text={<Typography variant="bodySmallC">{fieldConfig.label}</Typography>}
          />
        ) : (
          fieldConfig.label
        )
      }
      {...optionProps}
    >
      {renderTextField()}
    </Option>
  );
};
