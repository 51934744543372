import { faker } from '@faker-js/faker';
import { http, HttpResponse } from 'msw';
import {
  AAATemplate,
  AAATemplateCategory,
  AAATemplateHostType,
  aAATemplateHostTypeToJSON,
  AAATemplateStatus,
  aAATemplateStatusToJSON,
  ListAAATemplateReply,
} from 'shared/types/protoc-gen/bffaaa';
import {
  AgreementInfoReply,
  AgreementListReply,
  PaymentInfoReply,
  PaymentListReply,
  PaymentTrackerReply,
  SimplePaymentInfo,
} from 'shared/types/protoc-gen/bffbillsystem';
import { toHex } from 'viem';

const API_BASE_URL = process.env.REACT_APP_BFF_API_URL;

export const handlers = {
  'GET/bff/aaa/templates': http.get(`${API_BASE_URL}/bff/aaa/templates`, () => {
    const TEMPLATES_COUNT = 4;

    const generateAAATemplate = (category: AAATemplateCategory) =>
      ({
        id: faker.string.uuid(),
        name: faker.lorem.word(),
        description: faker.lorem.sentence(),
        type: faker.helpers.arrayElement([
          aAATemplateHostTypeToJSON(AAATemplateHostType.AAA_TEMPLATE_HOST_TYPE_CHATBOX),
          aAATemplateHostTypeToJSON(AAATemplateHostType.AAA_TEMPLATE_HOST_TYPE_HOSTYPE),
        ]),
        category,
        envList: Array.from({ length: 10 }).map(() => faker.lorem.word().toUpperCase()),
        chatSubpath: '/chat',
        configExample: faker.lorem.word(),
        iconLink: faker.image.url(),
        status: faker.helpers.arrayElement([
          aAATemplateStatusToJSON(AAATemplateStatus.AAA_TEMPLATE_STATUS_PUBLISHED),
          // aAATemplateStatusToJSON(AAATemplateStatus.AAA_TEMPLATE_STATUS_DELETED),
          // aAATemplateStatusToJSON(AAATemplateStatus.AAA_TEMPLATE_STATUS_PENDING),
          // aAATemplateStatusToJSON(AAATemplateStatus.AAA_TEMPLATE_STATUS_REJECTED),
          // aAATemplateStatusToJSON(AAATemplateStatus.AAA_TEMPLATE_STATUS_SUBMITTED),
        ]),
        requestSchema: {},
        responseSchema: {},
        port: faker.number.int({ min: 1000, max: 65535 }),
        featured: faker.datatype.boolean(),
      } as unknown as AAATemplate);

    return HttpResponse.json<ListAAATemplateReply>({
      appStore: Array.from({ length: TEMPLATES_COUNT }).map(() =>
        generateAAATemplate(AAATemplateCategory.AAA_TEMPLATE_TYPE_APP_STORE),
      ),
      selfOwned: Array.from({ length: 4 }).map(() =>
        generateAAATemplate(AAATemplateCategory.AAA_TEMPLATE_TYPE_APP_STORE),
      ),
      builtin: [],
    } as unknown as ListAAATemplateReply);
  }),
  'GET/bff/bill/agreements': http.get(`${API_BASE_URL}/bff/bill/agreements`, () => {
    return HttpResponse.json<AgreementListReply>({
      agreementInfo: Array.from({ length: 50 }).map(() => ({
        agreementId: faker.number.int({ max: 50 }),
        agreementLink: faker.internet.url(),
        paymentFrequency: faker.helpers.arrayElement(['Monthly', 'Quarterly', 'Yearly']),
        agreementStatus: faker.helpers.arrayElement(['Active', 'Pause', 'Terminated']),
        serviceFeeStatus: faker.helpers.arrayElement([
          'Pending Invoice',
          'Awaiting',
          'Paid',
          'Overdue',
        ]),
        serviceFeeNextPayment: faker.date.future().toISOString(),
        withdrawFeeStatus: faker.helpers.arrayElement([
          'Pending Invoice',
          'Awaiting',
          'Paid',
          'Overdue',
        ]),
        withdrawFeeNextPayment: faker.date.future().toISOString(),
        notes: faker.lorem.paragraph(),
        clientName: faker.person.fullName(),
      })),
    });
  }),
  'GET/bff/bill/agreements/:agreementId': http.get(
    `${API_BASE_URL}/bff/bill/agreements/:agreementId`,
    () => {
      return HttpResponse.json<AgreementInfoReply>({
        agreementId: faker.number.int({ max: 50 }),
        clientNameInAgreement: faker.person.fullName(),
        contactEmails: faker.internet.email(),
        bindingChains: [],
        agreementLink: faker.internet.url(),
        agreementStatus: faker.helpers.arrayElement(['Active', 'Pause', 'Terminated']),
        agreementScope: faker.helpers.arrayElement([1, 2, 3]),
        paymentFrequency: faker.helpers.arrayElement(['Monthly', 'Quarterly', 'Yearly']),
        amount: faker.number.int(),
        notes: faker.lorem.paragraph(),
        billingStatus: faker.helpers.arrayElement(['Healthy', 'Overdue']),
        clientName: faker.person.fullName(),
      });
    },
  ),
  'GET/bff/bill/payments': http.get(`${API_BASE_URL}/bff/bill/payments`, () => {
    return HttpResponse.json<PaymentListReply>({
      paymentInfo: Array.from({ length: 50 }).map(
        () =>
          ({
            agreementId: faker.number.int({ max: 50 }),
            paymentId: faker.number.int({ max: 50 }),
            type: faker.helpers.arrayElement(['Service fee', 'Withdraw fee', 'Additional fee']),
            subType: faker.helpers.arrayElement([
              'Deployment fee',
              'USDC Deployment fee',
              'Full node fee',
              'Consulting fee',
            ]),
            amount: faker.number.int({ max: 1000000 }).toString(),
            currency: faker.helpers.arrayElement(['USDT', 'ETH', 'BNB']),
            status: faker.helpers.arrayElement(['Awaiting', 'Paid', 'Overdue', 'PaidAfterOverdue']),
            invoiceTime: faker.date.future().toISOString(),
            paymentTime: faker.date.future().toISOString(),
            paymentTxhash: toHex(faker.string.uuid()),
          } satisfies SimplePaymentInfo),
      ),
    });
  }),
  'GET/bff/bill/payments/tracker': http.get(`${API_BASE_URL}/bff/bill/payments/tracker`, () => {
    return HttpResponse.json<PaymentTrackerReply>({
      serviceFee: {
        status: faker.helpers.arrayElement(['Awaiting', 'Paid', 'Overdue', 'PaidAfterOverdue']),
        paymentPeriodFrom: faker.date.future().toISOString(),
        paymentPeriodTo: faker.date.future().toISOString(),
        balance: faker.number.int({ max: 1000000 }).toString(),
      },
      withdrawFee: {
        status: faker.helpers.arrayElement(['Awaiting', 'Paid', 'Overdue', 'PaidAfterOverdue']),
        paymentPeriodFrom: faker.date.future().toISOString(),
        paymentPeriodTo: faker.date.future().toISOString(),
        overdraft: faker.number.int({ max: 1000000 }).toString(),
        currency: faker.helpers.arrayElement(['ETH', 'BTC', 'USD']),
      },
    });
  }),
  'GET/bff/bill/payments/:paymentId': http.get(
    `${API_BASE_URL}/bff/bill/payments/:paymentId`,
    () => {
      return HttpResponse.json<PaymentInfoReply>({
        agreementId: faker.number.int({ max: 50 }),
        paymentId: faker.number.int({ max: 50 }),
        type: faker.helpers.arrayElement(['Additional fee']),
        subType: faker.helpers.arrayElement(['Backup full node']),
        amount: faker.number.int({ max: 1000000 }).toString(),
        currency: faker.helpers.arrayElement(['USD', 'EUR', 'GBP', 'JPY']),
        status: faker.helpers.arrayElement(['Awaiting', 'Paid', 'Overdue', 'PaidAfterOverdue']),
        paymentPeriodFrom: faker.date.future().toISOString(),
        paymentPeriodTo: faker.date.future().toISOString(),
        nextPaymentDate: faker.date.future().toISOString(),
        invoiceTime: faker.date.future().toISOString(),
        paymentTime: faker.date.future().toISOString(),
        paymentTxhash: toHex(faker.string.uuid()),
      });
    },
  ),
};
