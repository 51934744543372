import { setupWorker } from 'msw/browser';

import { mockEndpoints } from './config';
import { handlers as _handlers } from './handlers';

const mockHandlers = Object.keys(_handlers)
  ?.filter(key => mockEndpoints.includes(key as keyof typeof _handlers))
  ?.map(key => _handlers?.[key as keyof typeof _handlers]);

console.debug('mockHandlers: ', mockHandlers);

export const worker = setupWorker(...mockHandlers);
