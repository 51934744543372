import { Editor } from '@monaco-editor/react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormTextFieldBase, Label } from 'shared/components/form';
import { MarkdownRenderer } from 'shared/components/MarkdownRenderer';
import {
  CreateNotificationRequest,
  CreateNotificationResponse,
} from 'shared/types/protoc-gen/intel-notification';
import { GetPersonalizationReply } from 'shared/types/protoc-gen/intel-personalization';

interface FormValues extends CreateNotificationRequest {
  baseURL: string;
  accessToken: string;
}

export const IntelDebugger = () => {
  const { addAlert } = useAlerts();

  const form = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      baseURL: 'dev-intel-agent.alt.technology',
      // ok to expose for dev env
      accessToken: 'eyJhbGciOiJIUzI1NiJ9.e30.HPEtGdBfCLlAOYpKodUOLixujC6D_LKfrEy4mmfQ3Ok',
      userId: 'jk',
      promptPrefix: '',
      promptSuffix: '',
    },
  });
  const formValues = form.watch();

  const http = axios.create({
    baseURL: `https://${formValues?.baseURL}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${formValues?.accessToken}`,
    },
  });

  const {
    data: getPersonalizationReply,
    isPending: isGetPersonalizationPending,
    isRefetching: isGetPersonalizationRefetching,
    refetch: refetchGetPersonalization,
  } = useQuery({
    queryKey: [],
    queryFn: async () => {
      const res = await http.get<GetPersonalizationReply>('/v1/personalization');

      return res.data;
    },
    enabled: !!formValues?.accessToken && !!formValues?.baseURL,
  });

  useEffect(() => {
    if (getPersonalizationReply?.personalization) {
      form.setValue(
        'promptPrefix',
        getPersonalizationReply.personalization?.[0].prompt?.prefix ||
          'The following content are some crypto-related news',
      );
      form.setValue(
        'promptSuffix',
        getPersonalizationReply.personalization?.[0].prompt?.suffix ||
          'Summarize the content in English with a Bloomberg-style messages',
      );
    }
  }, [getPersonalizationReply?.personalization, form]);

  const {
    data: createNotificationReply,
    isPending: isCreateNotificationPending,
    mutate: createNotification,
  } = useMutation({
    mutationKey: ['INTEL_CREATE_NOTIFICATION'],
    mutationFn: async (data: FormValues) => {
      const res = await http.post<CreateNotificationResponse>('/v1/notification', {
        promptPrefix: data.promptPrefix,
        promptSuffix: data.promptSuffix,
        userId: data.userId,
      } satisfies CreateNotificationRequest);

      return res.data;
    },
    onSuccess: () => {
      addAlert({
        title: 'Notification created successfully',
        desc: 'The notification has been created successfully',
        severity: ALERT_SEVERITY.SUCCESS,
      });
    },
    onError: () => {
      addAlert({
        title: 'Failed to create notification',
        desc: 'Please try again',
        severity: ALERT_SEVERITY.ERROR,
      });
    },
  });

  return (
    <Stack p={{ md: 5, sm: 3, xs: 3 }} spacing={3}>
      <FormProvider {...form}>
        <Stack component="form" onSubmit={form.handleSubmit(data => createNotification(data))}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Stack
                bgcolor={'#fff'}
                border={theme => `1px solid ${theme.colors.functional.subject.border}`}
                p={{ md: 5, sm: 3, xs: 3 }}
                spacing={5}
              >
                <Stack spacing={2}>
                  <Label label="base url">
                    <FormTextFieldBase<FormValues>
                      disabled
                      fieldConfig={{ name: 'baseURL' }}
                      helperTextShrink
                    />
                  </Label>
                  <Label label="access token">
                    <FormTextFieldBase<FormValues>
                      fieldConfig={{ name: 'accessToken' }}
                      helperTextShrink
                    />
                  </Label>
                </Stack>

                <Divider />

                <Stack spacing={2}>
                  <Label label="user id">
                    <FormTextFieldBase<FormValues>
                      fieldConfig={{ name: 'userId' }}
                      helperTextShrink
                    />
                  </Label>
                  <Label label="prompt prefix">
                    <FormTextFieldBase<FormValues>
                      disabled={isGetPersonalizationPending}
                      fieldConfig={{ name: 'promptPrefix' }}
                      helperTextShrink
                      minRows={4}
                      multiline
                    />
                  </Label>
                  <Label label="prompt suffix">
                    <FormTextFieldBase<FormValues>
                      disabled={isGetPersonalizationPending}
                      fieldConfig={{ name: 'promptSuffix' }}
                      helperTextShrink
                      minRows={4}
                      multiline
                    />
                  </Label>
                </Stack>

                <Stack alignItems="flex-end">
                  <LoadingButton
                    loading={isCreateNotificationPending}
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              </Stack>
            </Grid>
            <Grid item md={6} xs={12}>
              <Stack
                bgcolor={'#fff'}
                border={theme => `1px solid ${theme.colors.functional.subject.border}`}
                height={'100%'}
                overflow={'auto'}
                p={{ md: 5, sm: 3, xs: 3 }}
                spacing={5}
              >
                <Label label="formatted">
                  <Stack sx={{ '.MuiAccordion-root': { boxShadow: 0 } }} width={'100%'}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                        <Typography variant="bodySmallM">Info</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <MarkdownRenderer markdown={createNotificationReply?.info || ''} />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                        <Typography variant="bodySmallM">Prompt</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <MarkdownRenderer markdown={createNotificationReply?.prompt || ''} />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                        <Typography variant="bodySmallM">Summary</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <MarkdownRenderer markdown={createNotificationReply?.summary || ''} />
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                </Label>

                <Divider />

                <Label label="json">
                  <Editor
                    defaultLanguage="json"
                    height="80vh"
                    options={{
                      scrollBeyondLastLine: false,
                      showFoldingControls: 'always',
                    }}
                    theme="vs-dark"
                    value={JSON.stringify(createNotificationReply || {}, null, 2)}
                  />
                </Label>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </FormProvider>

      <Stack
        bgcolor={'#fff'}
        border={theme => `1px solid ${theme.colors.functional.subject.border}`}
        p={{ md: 5, sm: 3, xs: 3 }}
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography variant="h6">Personalization</Typography>
            {isGetPersonalizationPending && <CircularProgress size={16} />}
          </Stack>

          <LoadingButton
            loading={isGetPersonalizationRefetching}
            onClick={() => refetchGetPersonalization()}
            size="small"
            variant="contained"
          >
            Refresh
          </LoadingButton>
        </Stack>
        <Box component="pre">{JSON.stringify(getPersonalizationReply || {}, null, 2)}</Box>
      </Stack>
    </Stack>
  );
};
